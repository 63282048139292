import type { CSSProperties } from "react";
import { Business, BUSINESS_FIXTURES } from "./Business";

export interface Deal {
  type: "deal";
  deal_id: string;
  business_id: string;
  image_header_url: string;
  offer_text: string;
  expiration_date: string;
  tint_color?: string;
}

export interface DealWithRelations extends Deal {
  business: Business;
}

export function getDealProfileRoute(dealOrId: Deal | string): string {
  const id = typeof dealOrId === "string" ? dealOrId : dealOrId.deal_id;
  return `/deal/${id}`;
}

export function getDealRedeemRoute(dealOrId: Deal | string): string {
  return `${getDealProfileRoute(dealOrId)}/redeem`;
}

export function getDealTintStyle(dealOrColor: Deal | string): CSSProperties {
  const color = typeof dealOrColor === "string" ? dealOrColor : dealOrColor.tint_color;
  if (color) return { "--tint-color": color } as React.CSSProperties;
  return {};
}

const BUSINESS_FIXTURES_BY_SLUG: Record<string, Business> = {};
const BUSINESS_FIXTURES_BY_ID: Record<string, Business> = {};
BUSINESS_FIXTURES.forEach((business) => {
  BUSINESS_FIXTURES_BY_SLUG[business.slug] = business;
  BUSINESS_FIXTURES_BY_ID[business.business_id] = business;
});
const businessIdFromSlug = (slug: string) => {
  const biz = BUSINESS_FIXTURES_BY_SLUG[slug];
  if (!biz) throw new Error("Bad Business Slug");
  return biz.business_id;
};
const businessFromId = (id: string) => {
  const biz = BUSINESS_FIXTURES_BY_ID[id];
  if (!biz) throw new Error("Bad Business ID");
  return biz;
};

export const DEAL_FIXTURES: Deal[] = [
  {
    type: "deal",
    deal_id: "1",
    business_id: businessIdFromSlug("two-cousins-pizza-manheim"),
    image_header_url: "/assets/fixtures/pizzeria.jpg",
    offer_text: "Spend $25 and get $5 off your next order",
    expiration_date: "2022-09-31",
    tint_color: "#E84C2F",
  },
  {
    type: "deal",
    deal_id: "2",
    business_id: businessIdFromSlug("laserdome-lancaster"),
    image_header_url: "/assets/fixtures/laser-tag.jpg",
    offer_text: "50% off laser tag on Friday night from 9-12",
    expiration_date: "2022-08-31",
    tint_color: "#26BC35",
  },
  {
    type: "deal",
    deal_id: "3",
    business_id: businessIdFromSlug("fuego-latino-restaurant-catering"),
    image_header_url: "/assets/fixtures/chips-and-salsa.jpg",
    offer_text: "Free chips & salsa on your next dine-in order",
    expiration_date: "2022-09-30",
    tint_color: "#1A60A8",
  },
];

export const DEAL_FIXTURES_WITH_RELATIONS: DealWithRelations[] = DEAL_FIXTURES.map((deal) => ({
  ...deal,
  business: businessFromId(deal.business_id),
}));
