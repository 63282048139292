// Dependency imports
import {
  IonButton,
  IonButtons,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonListHeader,
  IonToggle,
  IonToolbar,
  useIonAlert,
  useIonPopover,
} from "@ionic/react";
import { navigate, options } from "ionicons/icons";
import classNames from "clsx";
// Project imports
import { useAppDispatch, useAppSelector } from "../data/hooks";
import { mapActions, Filter, ToggleFilterFn } from "../data/slices/map";
import { useGeolocate } from "../hooks/geolocate";
// Style imports
import styles from "./MapButtonsToolbar.module.scss";

interface MapButtonsToolbarProps {
  className?: string;
}

const MapButtonsToolbar: React.FC<MapButtonsToolbarProps> = ({ className }) => {
  const handleFilterButtonClick = useFilterButtonClickHandler();
  const handleGeolocateButtonClick = useGeolocateButtonClickHandler();
  return (
    <IonToolbar className={classNames(styles["MapButtonsToolbar"], className)}>
      <IonButtons slot="start">
        <IonButton onClick={handleFilterButtonClick}>
          <IonIcon slot="icon-only" icon={options} />
        </IonButton>
      </IonButtons>
      <IonButtons slot="end">
        <IonButton onClick={handleGeolocateButtonClick}>
          <IonIcon slot="icon-only" icon={navigate} />
        </IonButton>
      </IonButtons>
    </IonToolbar>
  );
};

function useGeolocateButtonClickHandler(): React.MouseEventHandler {
  const dispatch = useAppDispatch();
  const geolocate = useGeolocate();
  const [presentAlert] = useIonAlert();
  return async () => {
    try {
      const geolocationPromise = geolocate();
      await dispatch(mapActions.geolocate(geolocationPromise));
    } catch (err: unknown) {
      console.error("Failed to geolocate:", err);
      presentAlert("Unable to determine your location");
    }
  };
}

function useFilterButtonClickHandler(): React.MouseEventHandler {
  const filters = useAppSelector((state) => state.map.filters);
  const dispatch = useAppDispatch();
  const onFilterToggle: ToggleFilterFn = (id, value) => {
    dispatch(mapActions.toggledFilter({ id, value }));
  };
  const [present] = useIonPopover(MapFilterList, { filters, onFilterToggle });
  return (event) => {
    present({ event: event.nativeEvent });
  };
}

interface MapFilterListProps {
  filters: Filter[];
  onFilterToggle: ToggleFilterFn;
}

const MapFilterList: React.FC<MapFilterListProps> = ({ filters, onFilterToggle }) => {
  return (
    <IonList className={styles["MapFilterList"]}>
      <IonListHeader>Filter by Category</IonListHeader>
      {filters.map(({ id, label, enabled }) => (
        <IonItem key={id}>
          <IonLabel>{label}</IonLabel>
          <IonToggle
            checked={enabled}
            onIonChange={(event) => onFilterToggle(id, event.detail.checked)}
          />
        </IonItem>
      ))}
    </IonList>
  );
};

export default MapButtonsToolbar;
