import clsx from "clsx";
import { cloneElement } from "react";
import type { HTMLProps, ReactElement } from "react";

import { BemEntity } from "../utils/bem";

import "./CircleButton.scss";

const bem = new BemEntity("CircleButton");

type HTMLButtonProps = HTMLProps<HTMLButtonElement>;

export interface CircleButtonProps {
  icon: ReactElement;
  type?: "submit" | "reset" | "button";
}

export const CircleButton: React.FC<CircleButtonProps & HTMLButtonProps> = ({
  className,
  icon,
  type = "button",
  ...rest
}) => {
  return (
    <button className={clsx(className, bem.block())} type={type} {...rest}>
      {cloneElement(icon, {
        className: clsx(icon.props.className, bem.element("icon")),
      })}
    </button>
  );
};
