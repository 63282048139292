import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import {
  getUserData,
  setHasSeenTutorialData,
  setIsLoggedInData,
  setEmailData,
  setUsernameData,
} from "../dataApi";

export interface UserState {
  isLoggedIn: boolean;
  email: string | undefined;
  username: string | undefined;
  darkMode: boolean;
  hasSeenTutorial: boolean;
  isLoading: boolean;
}

export const initialState: UserState = {
  hasSeenTutorial: false,
  darkMode: false,
  isLoggedIn: false,
  isLoading: true,
  email: undefined,
  username: undefined,
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setData: (state, action: PayloadAction<Partial<UserState>>) => {
      return { ...state, ...action.payload };
    },
    setDarkMode: (state, action: PayloadAction<boolean>) => {
      state.darkMode = action.payload;
    },
  },
});

const { actions, reducer } = userSlice;
const thunks = {
  setLoggedIn: createAsyncThunk("user/setLoggedIn", async (isLoggedIn: boolean, { dispatch }) => {
    await setIsLoggedInData(isLoggedIn);
    const data: Partial<UserState> = { isLoggedIn };
    if (!isLoggedIn) data.username = undefined;
    dispatch(actions.setData(data));
  }),
  setEmail: createAsyncThunk("user/setEmail", async (arg: string | undefined, { dispatch }) => {
    await setEmailData(arg);
    dispatch(actions.setData({ email: arg }));
  }),
  setUsername: createAsyncThunk(
    "user/setUsername",
    async (arg: string | undefined, { dispatch }) => {
      await setUsernameData(arg);
      dispatch(actions.setData({ username: arg }));
    },
  ),
  setHasSeenTutorial: createAsyncThunk(
    "user/setHasSeenTutorial",
    async (arg: boolean, { dispatch }) => {
      await setHasSeenTutorialData(arg);
      dispatch(actions.setData({ hasSeenTutorial: arg }));
    },
  ),
  loadData: createAsyncThunk("user/loadData", async (_arg, { dispatch }) => {
    dispatch(actions.setData({ isLoading: true }));
    dispatch(actions.setData(await getUserData()));
    dispatch(actions.setData({ isLoading: false }));
  }),
};

const allActions = { ...actions, ...thunks };
export { reducer as userReducer, allActions as userActions };
