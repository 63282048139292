// Dependency imports
import {
  isFulfilled,
  createAsyncThunk,
  createSlice,
  isPending,
  isRejected,
} from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
// Project imports
import { BUSINESS_FIXTURES } from "../../models/Business";
import type { Business } from "../../models/Business";
import { FIXTURES as SUGGESTION_FIXTURES } from "../../models/SearchSuggestion";
import type { SearchSuggestion } from "../../models/SearchSuggestion";

// Constants

/** @see https://stackoverflow.com/a/43577944 */
const CENTER_OF_UNITED_STATES = { lat: 39.8097343, lng: -98.5556199 };
/** @see https://developers.google.com/maps/documentation/javascript/overview#zoom-levels */
export const ZOOM_CONTINENT = 5;

// Types

export type LatLng = google.maps.LatLngLiteral;

export enum AsyncStatus {
  empty = "empty",
  loading = "loading",
  fetching = "fetching",
  fulfilled = "fulfilled",
  rejected = "rejected",
}

function AsyncStatusPending(before: AsyncStatus): AsyncStatus {
  return before === AsyncStatus.empty ? AsyncStatus.loading : AsyncStatus.fetching;
}

export interface Filter {
  id: string;
  label: string;
  enabled: boolean;
}

export type ToggleFilterFn = (id: string, value?: boolean) => void;

// Fixtures

const PLACEHOLDER_FILTERS: Filter[] = [
  { id: "food-and-dining", label: "Food & Dining", enabled: true },
  { id: "retail-store", label: "Retail Store", enabled: true },
  { id: "entertainment", label: "Entertainment", enabled: true },
  { id: "car-wash", label: "Car Wash", enabled: true },
];

// The actual Map slice

export interface MapState {
  center: LatLng;
  zoom: number;
  geolocationStatus: AsyncStatus;
  geolocation?: LatLng;
  filters: Filter[];
  suggestionsStatus: AsyncStatus;
  suggestions: SearchSuggestion[];
  suggestionsVisible: boolean;
  searchText: string;
  resultsStatus: AsyncStatus;
  results: Business[];
}

export const initialState: MapState = {
  center: CENTER_OF_UNITED_STATES,
  zoom: ZOOM_CONTINENT,
  geolocationStatus: AsyncStatus.empty,
  geolocation: undefined,
  filters: PLACEHOLDER_FILTERS,
  suggestionsStatus: AsyncStatus.empty,
  suggestions: [],
  suggestionsVisible: false,
  searchText: "",
  resultsStatus: AsyncStatus.empty,
  results: [],
};

const thunks = {
  searchTextChanged: createAsyncThunk(
    "map/searchTextChanged",
    async (searchText: string): Promise<SearchSuggestion[]> => {
      if (!searchText) return [];
      // TODO
      return SUGGESTION_FIXTURES;
    },
  ),
  searchSubmitted: createAsyncThunk(
    "map/searchSubmitted",
    async (_searchText: string): Promise<Business[]> => {
      // TODO
      return [...BUSINESS_FIXTURES];
    },
  ),
  suggestionSelected: createAsyncThunk(
    "map/suggestionSelected",
    async (_suggestion: SearchSuggestion): Promise<Business[]> => {
      // TODO
      return [...BUSINESS_FIXTURES];
    },
  ),
  geolocate: createAsyncThunk(
    "map/geolocate",
    async (geolocationPromise: Promise<LatLng | undefined>) => geolocationPromise,
  ),
};

const searchResultsThunks = [thunks.searchSubmitted, thunks.suggestionSelected] as const;
const searchResultsActionMatchers = {
  pending: isPending(...searchResultsThunks),
  rejected: isRejected(...searchResultsThunks),
  fulfilled: isFulfilled(...searchResultsThunks),
};

const mapSlice = createSlice({
  name: "map",
  initialState,
  reducers: {
    toggledFilter: (state, action: PayloadAction<{ id: string; value?: boolean }>) => {
      const index = state.filters.findIndex((item) => item.id === action.payload.id);
      const filter = index > -1 ? state.filters[index] : null;
      if (filter) filter.enabled = action.payload.value ?? !filter.enabled;
    },
    suggestionsDismissed: (state) => {
      state.suggestionsVisible = false;
    },
    searchFieldFocused: (state) => {
      state.suggestionsVisible = state.suggestions.length > 0;
    },
  },
  extraReducers: (builder) => {
    // geolocate
    builder.addCase(thunks.geolocate.pending, (state) => {
      state.geolocationStatus = AsyncStatusPending(state.geolocationStatus);
    });
    builder.addCase(thunks.geolocate.rejected, (state) => {
      state.geolocationStatus = AsyncStatus.rejected;
    });
    builder.addCase(thunks.geolocate.fulfilled, (state, action) => {
      state.geolocationStatus = AsyncStatus.fulfilled;
      state.geolocation = action.payload;
    });
    // searchTextChanged
    builder.addCase(thunks.searchTextChanged.pending, (state, action) => {
      state.searchText = action.meta.arg;
      state.suggestionsStatus = AsyncStatusPending(state.suggestionsStatus);
      console.info(action);
    });
    builder.addCase(thunks.searchTextChanged.rejected, (state, action) => {
      state.suggestionsStatus = AsyncStatus.rejected;
      console.error(action);
    });
    builder.addCase(thunks.searchTextChanged.fulfilled, (state, action) => {
      state.suggestionsStatus = AsyncStatus.fulfilled;
      state.suggestions = action.payload;
      state.suggestionsVisible = state.suggestions.length > 0;
    });
    // searchTextSubmitted | suggestionSelected
    builder.addMatcher(searchResultsActionMatchers.pending, (state) => {
      state.resultsStatus = AsyncStatusPending(state.resultsStatus);
    });
    builder.addMatcher(searchResultsActionMatchers.rejected, (state) => {
      state.resultsStatus = AsyncStatus.rejected;
    });
    builder.addMatcher(searchResultsActionMatchers.fulfilled, (state, action) => {
      state.resultsStatus = AsyncStatus.fulfilled;
      state.results = action.payload;
      state.suggestionsVisible = state.results.length === 0;
    });
  },
});

const { actions, reducer } = mapSlice;

const allActions = { ...actions, ...thunks };
export { reducer as mapReducer, allActions as mapActions };
