// Dependency imports
import React from "react";
import { Network } from "@capacitor/network";
import type { PluginListenerHandle } from "@capacitor/core";
import type { ConnectionType } from "@capacitor/network";

export enum NetworkType {
  Unknown = "unknown",
  Cellular = "cellular",
  Wifi = "wifi",
  None = "none",
}

function normalizeValue(value: ConnectionType): NetworkType {
  // prettier-ignore
  switch (value) {
    case "cellular": return NetworkType.Cellular;
    case "wifi": return NetworkType.Wifi;
    case "none": return NetworkType.None;
    default: return NetworkType.Unknown;
  }
}

const defaultValue = NetworkType.Unknown;

function useNetworkContextState(): NetworkType {
  const [value, setValue] = React.useState(defaultValue);
  React.useEffect(() => {
    async function asyncEffect() {
      const status = await Network.getStatus();
      setValue(normalizeValue(status.connectionType));
    }
    asyncEffect();
  }, []);
  useNetworkChangeEffect(setValue);
  return value;
}

export function useNetworkChangeEffect(callback: (type: NetworkType) => void): void {
  const callbackRef = React.useRef<typeof callback>(callback);
  callbackRef.current = callback;
  React.useEffect(() => {
    let listenerHandle: PluginListenerHandle;
    let cleanupCalled = false;
    async function asyncEffect() {
      listenerHandle = await Network.addListener("networkStatusChange", (status) => {
        callbackRef.current(normalizeValue(status.connectionType));
      });
      if (cleanupCalled) {
        listenerHandle.remove();
      }
    }
    asyncEffect();
    return () => {
      listenerHandle?.remove();
      cleanupCalled = true;
    };
  }, []);
}

const NetworkContext = React.createContext<NetworkType>(defaultValue);
export default NetworkContext;

export function useNetworkType(): NetworkType {
  return React.useContext(NetworkContext);
}

export function withNetworkContext<P>(component: React.ComponentType<P>): React.ComponentType<P> {
  return function WithNetwork(props: P) {
    const contextState = useNetworkContextState();
    return (
      <NetworkContext.Provider value={contextState}>
        {React.createElement(component, props)}
      </NetworkContext.Provider>
    );
  };
}
