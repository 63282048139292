// Dependency imports
import { IonContent, IonPage } from "@ionic/react";
// Project imports
import ExploreCategoryGrid from "../components/ExploreCategoryGrid";
import ExploreDealsGrid from "../components/ExploreDealsGrid";
import ExploreFavoritesShelf from "../components/ExploreFavoritesShelf";
import ExplorePopularShelf from "../components/ExplorePopularShelf";
import { BemEntity } from "../utils/bem";
// Style imports
import "./Explore.scss";

const bem = new BemEntity("ExplorePage");

const ExplorePage: React.FC = () => {
  return (
    <IonPage id="explore-page" className={bem.block()}>
      <IonContent>
        <p className={bem.element("heading")}>Explore</p>
        <ExploreCategoryGrid className={bem.element("grid", "categories")} />
        <p className={bem.element("subheading")}>Your Favorite Spots</p>
        <ExploreFavoritesShelf className={bem.element("shelf", "offers")} />
        <p className={bem.element("subheading")}>Popular</p>
        <ExplorePopularShelf className={bem.element("shelf", "offers")} />
        <p className={bem.element("subheading")}>Latest Deals</p>
        <ExploreDealsGrid className={bem.element("grid", "offers")} />
      </IonContent>
    </IonPage>
  );
};

export default ExplorePage;
