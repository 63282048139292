// Dependency imports
import React from "react";
import { IonBackdrop, IonIcon, IonItem, IonLabel, IonList } from "@ionic/react";
import { location, searchCircle, storefront } from "ionicons/icons";
import classNames from "clsx";
// Project imports
import { useAppDispatch, useAppSelector } from "../data/hooks";
import { mapActions } from "../data/slices/map";
import { SEARCH_SUGGESTION_ICONS, SearchSuggestion } from "../models/SearchSuggestion";
// Style imports
import styles from "./MapSuggestionsMenu.module.scss";

interface MapSuggestionsMenuProps {
  className?: string;
}

const MapSuggestionsMenu: React.FC<MapSuggestionsMenuProps> = ({ className }) => {
  const ref = React.useRef<HTMLDivElement>(null);
  const isVisible = useAppSelector((state) => state.map.suggestionsVisible);
  const [contentHeight] = useClosestIonContentHeight(ref, [isVisible]);
  const suggestions = useAppSelector((state) => state.map.suggestions);
  const dispatch = useAppDispatch();
  const handleDismiss = () => {
    dispatch(mapActions.suggestionsDismissed());
  };
  const handleSelect = (item: SearchSuggestion) => {
    dispatch(mapActions.suggestionSelected(item));
  };
  return !isVisible ? null : (
    <div ref={ref} className={classNames(styles["MapSuggestionsMenu"], className)}>
      <IonBackdrop
        tappable
        className={styles["MapSuggestionsMenu__backdrop"]}
        style={{ height: contentHeight }}
        onIonBackdropTap={handleDismiss}
      />
      <div className={styles["MapSuggestionsMenu__anchor"]}>
        <IonList>
          {suggestions.slice(0, 6).map((item) => (
            <MapSuggestionsMenuItem
              key={`${item.type}:${item.label}`}
              item={item}
              onSelect={() => handleSelect(item)}
            />
          ))}
        </IonList>
      </div>
    </div>
  );
};

interface MapSuggestionsMenuItemProps {
  item: SearchSuggestion;
  onSelect: () => void;
}

const MapSuggestionsMenuItem: React.FC<MapSuggestionsMenuItemProps> = ({ item, onSelect }) => {
  let icon = "";
  let subtext = "";
  if (item.type === "location") {
    icon = location;
  } else if (item.type === "business") {
    icon = storefront;
    subtext = `${item.city}, ${item.state}`;
  } else if (item.type === "tag") {
    icon = (item.icon && SEARCH_SUGGESTION_ICONS[item.icon]) || searchCircle;
  }
  return (
    <IonItem button className={styles["MapSuggestionsMenuItem"]} detail={false} onClick={onSelect}>
      {icon ? <IonIcon icon={icon} slot="start" /> : null}
      <IonLabel>
        <h2>{item.label}</h2>
        {subtext ? <p>{subtext}</p> : null}
      </IonLabel>
    </IonItem>
  );
};

function useClosestIonContentHeight(
  domRef: React.RefObject<HTMLElement | null>,
  deps: React.DependencyList = [],
): [string, () => void] {
  const [height, setHeight] = React.useState("100%");
  const refreshHeight = React.useCallback(() => {
    const el = domRef.current?.closest("ion-content");
    const clientHeight = el?.clientHeight || 0;
    if (clientHeight) setHeight(`${clientHeight}px`);
    else setHeight("100%");
  }, [domRef]);
  React.useEffect(
    () => {
      refreshHeight();
      window.addEventListener("resize", refreshHeight);
      return () => {
        window.removeEventListener("resize", refreshHeight);
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [refreshHeight, ...deps],
  );
  return [height, refreshHeight];
}

export default MapSuggestionsMenu;
