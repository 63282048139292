// Dependency imports
import type React from "react";
import { IonTabs, IonRouterOutlet, IonTabBar, IonTabButton, IonIcon } from "@ionic/react";
import { Route } from "react-router";
import { map } from "ionicons/icons";
// Project imports
import { ReactComponent as ArchiveIcon } from "../assets/archive.svg";
import { ReactComponent as CogIcon } from "../assets/cog.svg";
import { ReactComponent as HomeIcon } from "../assets/home.svg";
import ExplorePage from "../pages/Explore";
import MapPage from "../pages/Map";
import WalletPage from "../pages/Wallet";
import SettingsPage from "../pages/Settings";
import { BemEntity } from "../utils/bem";
// import { useIonicBreakpoint } from "../hooks/media";
// Style imports
import "./MainTabs.scss";

const bem = new BemEntity("MainTabs");

const MainTabs: React.FC = () => {
  // TODO: Hide the tab-bar when the screen is wide enough to show the menu
  // const isLargeScreen = useIonicBreakpoint("lg");
  // const tabBarStyle = isLargeScreen ? { display: "none" } : undefined;
  return (
    <IonTabs className={bem.block()}>
      <IonRouterOutlet id="main-tabs">
        <Route exact path="/explore" render={() => <ExplorePage />} />
        <Route exact path="/map" render={() => <MapPage />} />
        <Route exact path="/wallet" render={() => <WalletPage />} />
        <Route exact path="/settings" render={() => <SettingsPage />} />
      </IonRouterOutlet>

      <IonTabBar slot="bottom" className={bem.element("tab-bar")}>
        <IonTabButton tab="explore" href="/explore">
          <HomeIcon className={bem.element("icon")} />
        </IonTabButton>
        <IonTabButton tab="map" href="/map">
          <IonIcon icon={map} />
        </IonTabButton>
        <IonTabButton tab="wallet" href="/wallet">
          <ArchiveIcon className={bem.element("icon")} />
        </IonTabButton>
        <IonTabButton tab="settings" href="/settings">
          <CogIcon className={bem.element("icon")} />
        </IonTabButton>
      </IonTabBar>
    </IonTabs>
  );
};

export default MainTabs;
