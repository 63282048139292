// Dependency imports
import React, { useState } from "react";
import { connect, ConnectedProps } from "react-redux";
import {
  IonHeader,
  IonToolbar,
  IonTitle,
  IonContent,
  IonPage,
  IonButtons,
  IonMenuButton,
  IonList,
  IonItem,
  IonAlert,
} from "@ionic/react";
// Project imports
import { userActions } from "../data/slices/user";
import type { RootState } from "../data/store";
// Style imports
import "./Account.scss";

const mapStateToProps = (state: RootState) => ({
  username: state.user.username,
});

const mapDispatchToProps = {
  setUsername: userActions.setUsername,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type AccountProps = ConnectedProps<typeof connector>;

export const AccountPage: React.FC<AccountProps> = ({ username, setUsername }) => {
  const [showAlert, setShowAlert] = useState(false);

  const clicked = (text: string) => {
    console.log(`Clicked ${text}`);
  };

  return (
    <IonPage id="account-page">
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton></IonMenuButton>
          </IonButtons>
          <IonTitle>Account</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        {username && (
          <div className="ion-padding-top ion-text-center">
            <img src="https://www.gravatar.com/avatar?d=mm&s=140" alt="avatar" />
            <h2>{username}</h2>
            <IonList inset>
              <IonItem onClick={() => clicked("Update Picture")}>Update Picture</IonItem>
              <IonItem onClick={() => setShowAlert(true)}>Change Username</IonItem>
              <IonItem onClick={() => clicked("Change Password")}>Change Password</IonItem>
              <IonItem routerLink="/support" routerDirection="none">
                Support
              </IonItem>
              <IonItem routerLink="/logout" routerDirection="none">
                Logout
              </IonItem>
            </IonList>
          </div>
        )}
      </IonContent>
      <IonAlert
        isOpen={showAlert}
        header="Change Username"
        buttons={[
          "Cancel",
          {
            text: "Ok",
            handler: (data) => {
              setUsername(data.username);
            },
          },
        ]}
        inputs={[
          {
            type: "text",
            name: "username",
            value: username,
            placeholder: "username",
          },
        ]}
        onDidDismiss={() => setShowAlert(false)}
      />
    </IonPage>
  );
};

export default connector(AccountPage);
