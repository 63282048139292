// Dependency imports
import clsx from "clsx";
import { IonIcon, useIonRouter } from "@ionic/react";
// Project imports
import type { CinchBusinessCategory } from "../data/types";
import { BemEntity } from "../utils/bem";
// Style imports
import "./ExploreCategoryCard.scss";

const bem = new BemEntity("ExploreCategoryCard");

export type ExploreCategoryCardProps = {
  className?: string;
  category: CinchBusinessCategory;
};

const renderCategoryIcon = (category: CinchBusinessCategory): JSX.Element => {
  if (typeof category.icon === "string") {
    return <IonIcon icon={category.icon} className={bem.element("icon")} />;
  } else {
    return <category.icon className={bem.element("icon")} />;
  }
};

export const ExploreCategoryCard: React.FC<ExploreCategoryCardProps> = ({
  category,
  className,
}) => {
  const router = useIonRouter();
  return (
    <a
      className={clsx(className, bem.block())}
      href={category.routerLink}
      onClick={(event) => {
        event.preventDefault();
        router.push(category.routerLink);
      }}
    >
      <div className={bem.element("circle")}>{renderCategoryIcon(category)}</div>
      <span className={bem.element("label")}>{category.name}</span>
    </a>
  );
};
