import isString from "lodash/isString";
import isArray from "lodash/isArray";
import isObject from "lodash/isObject";

export type BemJoinMode = "className" | "selector";
export type BemModifierScalar = string | number | boolean | null | undefined;
export type BemModifiersArray = BemModifierScalar[];
export type BemModifiersObject = { [key: string]: BemModifierScalar };
export type BemModifiers = BemModifierScalar | BemModifiersArray | BemModifiersObject;

// BEM token separators: http://getbem.com/naming/
const ELEMENT_TOKEN_SEPARATOR = "__";
const MODIFIER_TOKEN_SEPARATOR = "--";
const MODIFIER_KEY_VALUE_SEPARATOR = "-";

export function block(blockName: string, modifiers: BemModifiers, joinMode: BemJoinMode): string {
  const classes = composeModifiers(blockName, modifiers);
  return joinClasses(classes, joinMode);
}

export function element(
  blockName: string,
  elementName: string,
  modifiers: BemModifiers,
  joinMode: BemJoinMode,
): string {
  const classes = composeModifiers(composeElement(blockName, elementName), modifiers);
  return joinClasses(classes, joinMode);
}

export class BemEntity {
  public blockName: string;
  public joinMode: BemJoinMode;
  constructor(blockName: string, joinMode?: BemJoinMode) {
    this.blockName = blockName;
    this.joinMode = joinMode || "className";
  }

  public block(modifiers?: BemModifiers, joinMode?: BemJoinMode): string {
    return block(this.blockName, modifiers, joinMode ?? this.joinMode);
  }

  public element(elementName: string, modifiers?: BemModifiers, joinMode?: BemJoinMode): string {
    return element(this.blockName, elementName, modifiers, joinMode ?? this.joinMode);
  }
}

// Utility API

function joinClasses(classes: string[], joinMode: BemJoinMode): string {
  if (!classes.length) return "";
  if (joinMode === "className") return classes.join(" ");
  if (joinMode === "selector") return "." + classes.join(".");
  throw Error("Unexpected BEM joinClasses mode: " + joinMode);
}

function composeElement(blockName: string, elementName: string): string {
  return blockName + ELEMENT_TOKEN_SEPARATOR + elementName;
}

function composeModifier(baseName: string, modifier: string): string {
  return baseName + MODIFIER_TOKEN_SEPARATOR + modifier;
}

function composeModifiers(baseName: string, modifiers: BemModifiers) {
  const affirmativeModifiers = parseModifiers(modifiers);
  if (affirmativeModifiers.length) {
    const modifiersWithBase = affirmativeModifiers.map((modifier) => {
      return composeModifier(baseName, modifier);
    });
    return [baseName, ...modifiersWithBase];
  }
  return [baseName];
}

function parseModifiers(modifiers: BemModifiers): string[] {
  if (isArray(modifiers)) {
    // Prune negatory modifiers (except zero)
    return modifiers.filter((mod) => mod === 0 || !!mod).map(String);
  }
  if (isString(modifiers)) {
    // Convert modifiers string into array
    return parseModifiers(modifiers.split(" "));
  }
  if (isObject(modifiers)) {
    // Convert modifiers object into array
    return parseModifiers(squashModifiersObject(modifiers));
  }
  return [];
}

function squashModifiersObject(modifiers: BemModifiersObject): (string | null)[] {
  // Convert modifiers object into array of 'key'/'key-value' strings
  return Object.keys(modifiers).map((key) => {
    const value = modifiers[key];
    if (value === false || value == null) {
      return null;
    }
    if (value === true || value === "") {
      return key;
    }
    return key + MODIFIER_KEY_VALUE_SEPARATOR + value;
  });
}
