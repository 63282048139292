// Dependency imports
import { Browser } from "@capacitor/browser";
import { IonContent, IonPage } from "@ionic/react";
import { useHistory } from "react-router";
// Project imports
import { ReactComponent as AppleLogo } from "../assets/auth-apple.svg";
import { ReactComponent as FacebookLogo } from "../assets/auth-facebook.svg";
import { ReactComponent as GoogleLogo } from "../assets/auth-google.svg";
import { ReactComponent as CinchLogotype } from "../assets/cinch-logotype.svg";
import { Button } from "../components/Button";
import { useMenuDisabled } from "../hooks/menu-disabled";
import { BemEntity } from "../utils/bem";
// Style imports
import "./Welcome.scss";

const bem = new BemEntity("WelcomePage");

const WelcomePage: React.FC = () => {
  const history = useHistory();
  useMenuDisabled();

  return (
    <IonPage id="welcome-page" className={bem.block()}>
      <IonContent>
        <div className={bem.element("logotype")}>
          <CinchLogotype />
        </div>
        <p className={bem.element("greeting")}>Welcome, let's get things started.</p>
        <section className={bem.element("auth-social")}>
          <Button
            className={bem.element("button")}
            label="Continue with Facebook"
            leadingIcon={<FacebookLogo />}
            onClick={() => history.push("/auth/facebook")}
          />
          <Button
            className={bem.element("button")}
            label="Continue with Google"
            leadingIcon={<GoogleLogo />}
            onClick={() => history.push("/auth/google")}
          />
          <Button
            className={bem.element("button")}
            label="Continue with Apple"
            leadingIcon={<AppleLogo />}
            onClick={() => history.push("/auth/apple")}
          />
        </section>
        <section className={bem.element("auth-email")}>
          <Button
            className={bem.element("button", "lime")}
            label="Sign up with email"
            onClick={() => history.push("/auth/signup")}
          />
          <Button
            className={bem.element("button", "sage")}
            label="Login with email"
            onClick={() => history.push("/auth/email")}
          />
          <Button
            className={bem.element("button", "link")}
            label="Learn more about Cinch"
            onClick={() => Browser.open({ url: "https://www.joincinch.com/" })}
          />
        </section>
      </IonContent>
    </IonPage>
  );
};

export default WelcomePage;
