// Dependency imports
import clsx from "clsx";
import { connect, ConnectedProps } from "react-redux";
// Project imports
import { ReactComponent as AlcoholIcon } from "../assets/categories/alcohol.svg";
import { ReactComponent as AutoRepairIcon } from "../assets/categories/auto-repair.svg";
import { ReactComponent as BurgerIcon } from "../assets/categories/burger.svg";
import { ReactComponent as FitnessIcon } from "../assets/categories/fitness.svg";
import { ReactComponent as GlobeIcon } from "../assets/categories/globe.svg";
import { ReactComponent as PawIcon } from "../assets/categories/paw.svg";
import { ReactComponent as ShoppingIcon } from "../assets/categories/shopping.svg";
import { ReactComponent as SpaIcon } from "../assets/categories/spa.svg";
import type { RootState } from "../data/store";
import { BemEntity } from "../utils/bem";
import { ExploreCategoryCard } from "./ExploreCategoryCard";
// Style imports
import "./ExploreCategoryGrid.scss";

const bem = new BemEntity("ExploreCategoryGrid");

// TODO Move this list somewhere else, ideally server-side
const categoryFixtures = [
  {
    id: "food",
    name: "Food",
    icon: BurgerIcon,
    routerLink: "/category/food",
  },
  {
    id: "nightlife",
    name: "Nightlife",
    icon: AlcoholIcon,
    routerLink: "/category/nightlife",
  },
  {
    id: "spas-and-salons",
    name: "Spas & Salons",
    icon: SpaIcon,
    routerLink: "/category/spas-and-salons",
  },
  {
    id: "fitness",
    name: "Fitness",
    icon: FitnessIcon,
    routerLink: "/category/fitness",
  },
  {
    id: "shopping",
    name: "Shopping",
    icon: ShoppingIcon,
    routerLink: "/category/shopping",
  },
  {
    id: "auto-repair",
    name: "Auto Repair",
    icon: AutoRepairIcon,
    routerLink: "/category/auto-repair",
  },
  {
    id: "pets",
    name: "Pets",
    icon: PawIcon,
    routerLink: "/category/pets",
  },
  {
    id: "travel",
    name: "Travel",
    icon: GlobeIcon,
    routerLink: "/category/travel",
  },
];

const mapStateToProps = (_state: RootState) => ({
  categories: categoryFixtures,
});

const connector = connect(mapStateToProps);
type ConnectorProps = ConnectedProps<typeof connector>;

export interface ExploreCategoryGridProps extends ConnectorProps {
  className?: string;
}

export const ExploreCategoryGrid: React.FC<ExploreCategoryGridProps> = ({
  categories,
  className,
}) => {
  return (
    <section className={clsx(className, bem.block())}>
      {categories.map((category) => (
        <ExploreCategoryCard
          key={category.routerLink}
          className={bem.element("item")}
          category={category}
        />
      ))}
    </section>
  );
};

export default connector(ExploreCategoryGrid);
