// Dependency imports
import clsx from "clsx";
import { useIonRouter } from "@ionic/react";
// Project imports
import { getBusinessProfileRoute } from "../models/Business";
import { getDealProfileRoute, getDealRedeemRoute, getDealTintStyle } from "../models/Deal";
import type { DealWithRelations } from "../models/Deal";
import { BemEntity } from "../utils/bem";
// Style imports
import "./DealListItem.scss";

const bem = new BemEntity("DealListItem");

interface DealListItemProps {
  className?: string;
  deal: DealWithRelations;
}

export const DealListItem: React.FC<DealListItemProps> = ({ className, deal }) => {
  const router = useIonRouter();
  const businessDetailRoute = getBusinessProfileRoute(deal.business);
  const dealDetailRoute = getDealProfileRoute(deal);
  const redeemRoute = getDealRedeemRoute(deal);
  const style = getDealTintStyle(deal);
  return (
    <div className={clsx(bem.block(), className)} style={style}>
      <a
        className={bem.element("circle")}
        href={businessDetailRoute}
        onClick={(event) => {
          event.preventDefault();
          router.push(businessDetailRoute);
        }}
      >
        {Boolean(deal.business.images.logo) && (
          <img
            className={bem.element("biz-logo")}
            src={deal.business.images.logo}
            alt={"Logo for " + deal.business.name}
          />
        )}
      </a>
      <div className={bem.element("stack")}>
        <a
          className={bem.element("biz-name")}
          href={businessDetailRoute}
          onClick={(event) => {
            event.preventDefault();
            router.push(businessDetailRoute);
          }}
        >
          {deal.business.name}
        </a>
        <a
          className={bem.element("message")}
          href={dealDetailRoute}
          onClick={(event) => {
            event.preventDefault();
            router.push(dealDetailRoute);
          }}
        >
          {deal.offer_text}
        </a>
      </div>
      <button
        className={bem.element("button")}
        onClick={(event) => {
          event.preventDefault();
          router.push(redeemRoute);
        }}
      >
        Redeem
      </button>
    </div>
  );
};
