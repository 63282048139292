// Dependency imports
import { Browser } from "@capacitor/browser";
import { IonContent, IonPage, useIonRouter } from "@ionic/react";
import { connect, ConnectedProps } from "react-redux";
import { withRouter, RouteComponentProps } from "react-router";
// Project imports
import { ReactComponent as CloseIcon } from "../assets/close.svg";
import { CircleButton } from "../components/CircleButton";
import type { RootState } from "../data/store";
import { getBusinessProfileRoute } from "../models/Business";
import { getDealRedeemRoute, getDealTintStyle } from "../models/Deal";
import { DEAL_FIXTURES_WITH_RELATIONS } from "../models/Deal";
import type { DealWithRelations } from "../models/Deal";
import { BemEntity } from "../utils/bem";
// Style imports
import "./DealProfile.scss";
import { DateTime } from "../components/DateTime";

const bem = new BemEntity("DealProfilePage");

type RouteParams = { deal_id: string };

interface StateProps {
  deal: DealWithRelations;
}

const mapStateToProps = (
  _state: RootState,
  routerProps: RouteComponentProps<RouteParams>,
): StateProps => {
  const deal = DEAL_FIXTURES_WITH_RELATIONS.find((deal) => {
    return deal.deal_id === routerProps.match.params.deal_id;
  });
  if (!deal) throw new Error("Invalid deal ID");
  return { deal };
};

const connector = connect(mapStateToProps);
type ConnectorProps = ConnectedProps<typeof connector>;

interface DealProfilePageProps extends ConnectorProps, RouteComponentProps<RouteParams> {}

const DealProfilePage: React.FC<DealProfilePageProps> = ({ deal }) => {
  const router = useIonRouter();
  const businessDetailRoute = getBusinessProfileRoute(deal.business);
  const redeemRoute = getDealRedeemRoute(deal);
  const businessWebsiteUrl = deal.business.website;
  const style = getDealTintStyle(deal);
  return (
    <IonPage id="deal-profile-page" className={bem.block()} style={style}>
      <IonContent>
        <CircleButton
          className={bem.element("close-button")}
          icon={<CloseIcon />}
          onClick={(event) => {
            event.preventDefault();
            if (router.canGoBack()) {
              router.goBack();
            } else {
              router.push("/explore", "root");
            }
          }}
        />
        <div className={bem.element("biz-info")}>
          <a
            className={bem.element("biz-name")}
            href={businessDetailRoute}
            onClick={(event) => {
              event.preventDefault();
              router.push(businessDetailRoute);
            }}
          >
            {deal.business.name}
          </a>
          <address className={bem.element("biz-address")}>
            {[
              deal.business.address.line1,
              deal.business.address.line2,
              deal.business.address.line3,
              deal.business.address.level4,
              deal.business.address.level3,
              deal.business.address.level2,
              deal.business.address.level1,
            ]
              .filter(Boolean)
              .join(", ")}
          </address>
          {typeof businessWebsiteUrl === "string" && (
            <a
              className={bem.element("biz-website")}
              href={businessWebsiteUrl}
              onClick={(event) => {
                event.preventDefault();
                Browser.open({ url: businessWebsiteUrl });
              }}
            >
              {businessWebsiteUrl}
            </a>
          )}
        </div>
        <div className={bem.element("deal-info")}>
          <p className={bem.element("expires")}>
            Deal expires{" "}
            <DateTime
              dateTime={deal.expiration_date}
              parseFormat="YYYY-MM-DD"
              printFormat="MM/DD/YYYY"
            />
          </p>
          <p className={bem.element("offer-text")}>{deal.offer_text}</p>
        </div>
        <div className={bem.element("buttons")}>
          <button className={bem.element("button", "favorite")}>Add to Favorites</button>
          <a
            className={bem.element("button", "redeem")}
            href={redeemRoute}
            onClick={(event) => {
              event.preventDefault();
              router.push(redeemRoute);
            }}
          >
            Redeem Offer
          </a>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default withRouter(connector(DealProfilePage));
