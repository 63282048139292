export type MapTypeStyle = google.maps.MapTypeStyle;
export type MapTypeStyles = MapTypeStyle[];

type ElementTypeMap = Map<string, MapTypeStyle["stylers"]>;
type FeatureTypeMap = Map<string, ElementTypeMap>;

export function mergeMapTypeStyles(a: MapTypeStyles, b: MapTypeStyles): MapTypeStyles {
  const featureTypeMap: FeatureTypeMap = new Map();
  for (const { featureType, elementType, stylers } of [...a, ...b]) {
    let elementTypeMap: ElementTypeMap | undefined = featureTypeMap.get(featureType || "all");
    if (!elementTypeMap) {
      featureTypeMap.set(featureType || "all", (elementTypeMap = new Map()));
    }
    const existingStylers = elementTypeMap.get(elementType || "all") ?? [];
    elementTypeMap.set(elementType || "all", [existingStylers, ...stylers]);
  }
  const results: MapTypeStyles = [];
  for (const [featureType, elementTypeMap] of featureTypeMap.entries()) {
    for (const [elementType, stylers] of elementTypeMap.entries()) {
      results.push({ featureType, elementType, stylers });
    }
  }
  return results;
}

export const darkThemeMapTypeStyles: MapTypeStyles = [
  { elementType: "geometry", stylers: [{ color: "#242f3e" }] },
  { elementType: "labels.text.stroke", stylers: [{ color: "#242f3e" }] },
  { elementType: "labels.text.fill", stylers: [{ color: "#746855" }] },
  {
    featureType: "administrative.locality",
    elementType: "labels.text.fill",
    stylers: [{ color: "#d59563" }],
  },
  {
    featureType: "poi",
    elementType: "labels.text.fill",
    stylers: [{ color: "#d59563" }],
  },
  {
    featureType: "poi.park",
    elementType: "geometry",
    stylers: [{ color: "#263c3f" }],
  },
  {
    featureType: "poi.park",
    elementType: "labels.text.fill",
    stylers: [{ color: "#6b9a76" }],
  },
  {
    featureType: "road",
    elementType: "geometry",
    stylers: [{ color: "#38414e" }],
  },
  {
    featureType: "road",
    elementType: "geometry.stroke",
    stylers: [{ color: "#212a37" }],
  },
  {
    featureType: "road",
    elementType: "labels.text.fill",
    stylers: [{ color: "#9ca5b3" }],
  },
  {
    featureType: "road.highway",
    elementType: "geometry",
    stylers: [{ color: "#746855" }],
  },
  {
    featureType: "road.highway",
    elementType: "geometry.stroke",
    stylers: [{ color: "#1f2835" }],
  },
  {
    featureType: "road.highway",
    elementType: "labels.text.fill",
    stylers: [{ color: "#f3d19c" }],
  },
  {
    featureType: "transit",
    elementType: "geometry",
    stylers: [{ color: "#2f3948" }],
  },
  {
    featureType: "transit.station",
    elementType: "labels.text.fill",
    stylers: [{ color: "#d59563" }],
  },
  {
    featureType: "water",
    elementType: "geometry",
    stylers: [{ color: "#17263c" }],
  },
  {
    featureType: "water",
    elementType: "labels.text.fill",
    stylers: [{ color: "#515c6d" }],
  },
  {
    featureType: "water",
    elementType: "labels.text.stroke",
    stylers: [{ color: "#17263c" }],
  },
];
