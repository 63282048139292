// Dependency imports
import React from "react";
// Project imports
import { AsyncStatus } from "../data/slices/map";
import { useDarkTheme } from "../hooks/dark-theme";
import type { Business } from "../models/Business";

type MarkerOptions = google.maps.MarkerOptions;

interface ThemedMarkerOptions {
  light?: MarkerOptions;
  dark?: MarkerOptions;
}

interface BusinessMarkerOptionsVariants {
  [variant: string]: ThemedMarkerOptions;
}

const GENERATE_BUSINESS_MARKER_VARIANT_OPTIONS = {
  large: {
    bubbleWidth: 240,
    bubbleHeight: 150,
    bubbleRadius: 5,
    bubblePadding: 10,
    arrowWidth: 40,
    arrowHeight: 20,
    shadowBlur: 10,
  },
  medium: {
    bubbleWidth: 120,
    bubbleHeight: 75,
    bubbleRadius: 3,
    bubblePadding: 5,
    arrowWidth: 20,
    arrowHeight: 10,
    shadowBlur: 5,
  },
  small: {
    bubbleWidth: 60,
    bubbleHeight: 37,
    bubbleRadius: 2,
    bubblePadding: 2,
    arrowWidth: 10,
    arrowHeight: 5,
    shadowBlur: 2,
  },
} as const;

const ZOOM_MEDIUM_BREAKPOINT = 14;
const ZOOM_SMALL_BREAKPOINT = 11;

type BusinessMarkerVariant = keyof typeof GENERATE_BUSINESS_MARKER_VARIANT_OPTIONS;

export function useBusinessMarkerOptions(
  results: Business[],
  currentZoom: number,
): [AsyncStatus, MarkerOptions[]] {
  const cacheRef = React.useRef(new Map<Business, BusinessMarkerOptionsVariants>());
  const [markersStatus, setMarkersStatus] = React.useState<AsyncStatus>(AsyncStatus.empty);
  const [markers, setMarkers] = React.useState<MarkerOptions[]>([]);
  const [darkTheme] = useDarkTheme();
  // Decide which variant to show based on zoom level
  let variantKey: BusinessMarkerVariant = "large";
  if (currentZoom < ZOOM_MEDIUM_BREAKPOINT) variantKey = "medium";
  if (currentZoom < ZOOM_SMALL_BREAKPOINT) variantKey = "small";
  // Manage the cache of marker options for each variant and theme
  const getCachedMarkerOptions = React.useCallback(
    (cacheKey: Business): MarkerOptions | Promise<MarkerOptions> => {
      const cache = cacheRef.current;
      const themeKey = darkTheme ? "dark" : "light";
      const cachedValue = cache.get(cacheKey);
      const cachedOptions = cachedValue?.[variantKey]?.[themeKey];
      if (cachedOptions) return cachedOptions;
      return generateBusinessMarkerOptions(cacheKey, {
        ...GENERATE_BUSINESS_MARKER_VARIANT_OPTIONS[variantKey],
        darkTheme,
      }).then((generatedOptions) => {
        const storeValue = {
          ...cachedValue,
          [variantKey]: {
            ...cachedValue?.[variantKey],
            [themeKey]: generatedOptions,
          },
        };
        cache.set(cacheKey, storeValue);
        return generatedOptions;
      });
    },
    [variantKey, darkTheme],
  );
  // Refresh the markers when the theme/variant changes
  React.useEffect(() => {
    const lookups = results.map((result) => getCachedMarkerOptions(result));
    const allResolved = lookups.every((lookup) => !("then" in lookup));
    if (allResolved) {
      setMarkers(lookups as MarkerOptions[]);
      setMarkersStatus(AsyncStatus.fulfilled);
    } else {
      setMarkersStatus(AsyncStatus.fetching);
      Promise.all(lookups)
        .then((resolved) => {
          setMarkers(resolved);
          setMarkersStatus(AsyncStatus.fulfilled);
          return;
        })
        .catch((err: unknown) => {
          console.error(err);
          setMarkersStatus(AsyncStatus.rejected);
          return;
        });
    }
  }, [results, getCachedMarkerOptions]);
  // Clean up the cache, revoke all of the cached marker icon URLs
  React.useEffect(() => {
    const cache = cacheRef.current;
    return () => {
      for (const [, variants] of cache.entries()) {
        for (const [, themedMarkerOptions] of Object.entries(variants)) {
          for (const themeKey of ["light", "dark"] as const) {
            const icon = themedMarkerOptions[themeKey]?.icon;
            if (icon && typeof icon === "object" && "url" in icon) {
              URL.revokeObjectURL(icon.url);
            }
          }
        }
      }
      cache.clear();
    };
  }, []);
  return [markersStatus, markers];
}

export function generateGeolocationMarkerOptions(geolocation: {
  lat: number;
  lng: number;
}): google.maps.MarkerOptions {
  return {
    title: "You are here",
    position: geolocation,
    icon: {
      path: google.maps.SymbolPath.CIRCLE,
      fillColor: "#00C",
      fillOpacity: 1.0,
      strokeColor: "#FFF",
      strokeOpacity: 1.0,
      strokeWeight: 4,
      rotation: 0,
      scale: 10,
    },
  };
}

export interface GenerateBusinessMarkerOptions {
  bubbleWidth: number;
  bubbleHeight: number;
  bubbleRadius: number;
  bubblePadding: number;
  arrowWidth: number;
  arrowHeight: number;
  shadowBlur: number;
  darkTheme: boolean;
}

export async function generateBusinessMarkerOptions(
  data: Business,
  options: GenerateBusinessMarkerOptions,
): Promise<google.maps.MarkerOptions> {
  const canvas = document.createElement("canvas");
  canvas.width = options.bubbleWidth + options.shadowBlur * 2;
  canvas.height = options.bubbleHeight + options.arrowHeight + options.shadowBlur * 2;
  const ctx = canvas.getContext("2d");
  if (!ctx) throw new Error("Unable to generate icon for Business: " + data.name);
  const businessMapImage = await loadImage(data.images.map);
  const { anchor, shape } = drawBusinessMarkerIcon(ctx, { ...options, image: businessMapImage });
  const size = new google.maps.Size(canvas.width, canvas.height);
  const origin = new google.maps.Point(0, 0);
  // Render the icon canvas to a blob object URL
  const url = await new Promise<string>((resolve, reject) => {
    canvas.toBlob((blob) => {
      if (blob) {
        resolve(URL.createObjectURL(blob));
      } else {
        reject(new Error("Unable to render icon for Business: " + data.name));
      }
    });
  });
  return {
    title: data.name,
    position: data.location,
    icon: { url, anchor, size, origin },
    shape,
  };
}

interface DrawBusinessMarkerOptions extends GenerateBusinessMarkerOptions {
  image: CanvasImageSource;
}

interface DrawMarkerResult {
  anchor: google.maps.Point;
  shape: google.maps.MarkerShape;
}

function drawBusinessMarkerIcon(
  ctx: CanvasRenderingContext2D,
  {
    bubbleWidth,
    bubbleHeight,
    bubbleRadius,
    bubblePadding,
    arrowWidth,
    arrowHeight,
    shadowBlur,
    image,
    darkTheme,
  }: DrawBusinessMarkerOptions,
): DrawMarkerResult {
  // Calculate offsets
  const bubbleTop = shadowBlur;
  const bubbleLeft = shadowBlur;
  const bubbleRight = bubbleLeft + bubbleWidth;
  const bubbleBottom = bubbleTop + bubbleHeight;
  const arrowLeft = bubbleLeft + bubbleWidth / 2 - arrowWidth / 2;
  const arrowRight = arrowLeft + arrowWidth;
  const arrowCenter = arrowLeft + arrowWidth / 2;
  const arrowBottom = bubbleBottom + arrowHeight;

  // Start drawing a rounded-rectangle path
  ctx.beginPath();
  ctx.moveTo(bubbleLeft + bubbleRadius, bubbleTop);
  ctx.lineTo(bubbleRight - bubbleRadius, bubbleTop);
  ctx.quadraticCurveTo(bubbleRight, bubbleTop, bubbleRight, bubbleTop + bubbleRadius);
  ctx.lineTo(bubbleRight, bubbleBottom - bubbleRadius);
  ctx.quadraticCurveTo(bubbleRight, bubbleBottom, bubbleRight - bubbleRadius, bubbleBottom);
  // Inject the arrow into the bottom-center of the bubble path
  ctx.lineTo(arrowRight, bubbleBottom);
  ctx.lineTo(arrowCenter, arrowBottom);
  ctx.lineTo(arrowLeft, bubbleBottom);
  // Resume drawing the rounded rectangle path
  ctx.lineTo(bubbleLeft + bubbleRadius, bubbleBottom);
  ctx.quadraticCurveTo(bubbleLeft, bubbleBottom, bubbleLeft, bubbleBottom - bubbleRadius);
  ctx.lineTo(bubbleLeft, bubbleTop + bubbleRadius);
  ctx.quadraticCurveTo(bubbleLeft, bubbleTop, bubbleLeft + bubbleRadius, bubbleTop);
  ctx.closePath();

  // Fill the path and add a drop shadow
  ctx.fillStyle = darkTheme ? "#111" : "#fff";
  ctx.shadowColor = darkTheme ? "rgba(255, 255, 255, 0.3)" : "rgba(0, 0, 0, 0.3)";
  ctx.shadowBlur = shadowBlur;
  ctx.shadowOffsetX = 0;
  ctx.shadowOffsetY = 0;
  ctx.fill();

  // Draw the image in the center of the bubble
  // TODO Preserve aspect ratio
  const imageX = bubbleLeft + bubblePadding;
  const imageY = bubbleTop + bubblePadding;
  const imageW = bubbleWidth - bubblePadding * 2;
  const imageH = bubbleHeight - bubblePadding * 2;
  ctx.drawImage(image, imageX, imageY, imageW, imageH);

  return {
    anchor: new google.maps.Point(arrowCenter, arrowBottom),
    shape: { type: "rect", coords: [bubbleLeft, bubbleTop, bubbleRight, bubbleBottom] },
  };
}

async function loadImage(src: string): Promise<HTMLImageElement> {
  return new Promise<HTMLImageElement>((resolve, reject) => {
    const image = new Image();
    image.addEventListener("load", () => resolve(image), false);
    image.addEventListener("error", (event) => {
      console.error("Image error event:", event);
      reject(new Error("Unable to load image: " + src));
    });
    image.src = src;
  });
}
