// Dependency imports
import { IonContent, IonPage } from "@ionic/react";
import { useState } from "react";
// Project imports
import { DealListItem } from "../components/DealListItem";
import { DEAL_FIXTURES_WITH_RELATIONS } from "../models/Deal";
import { BemEntity } from "../utils/bem";
// Style imports
import "./Wallet.scss";

const bem = new BemEntity("WalletPage");

const WALLET_FILTER_OPTIONS = [
  { id: "saved", label: "Saved" },
  { id: "archive", label: "Archive" },
] as const;

type WalletFilterId = typeof WALLET_FILTER_OPTIONS[number]["id"];

interface WalletFilterListProps {
  selected: WalletFilterId;
  onSelect: (id: WalletFilterId) => void;
}

const WalletFilterList: React.FC<WalletFilterListProps> = (props) => {
  return (
    <div className={bem.element("filter-list")}>
      {WALLET_FILTER_OPTIONS.map(({ id, label }) => (
        <button
          key={id}
          className={bem.element("filter-option", {
            selected: props.selected === id,
          })}
          onClick={() => props.onSelect(id)}
        >
          {label}
        </button>
      ))}
    </div>
  );
};

const WalletPage: React.FC = () => {
  const [filterType, setFilterType] = useState<WalletFilterId>("saved");
  // TODO: Use deals from Redux store
  const deals = DEAL_FIXTURES_WITH_RELATIONS;

  return (
    <IonPage id="explore-page" className={bem.block()}>
      <IonContent>
        <p className={bem.element("heading")}>My Offers</p>
        <WalletFilterList selected={filterType} onSelect={setFilterType} />
        <section className={bem.element("deals-list")}>
          {deals.map((deal) => (
            <DealListItem className={bem.element("deal-item")} deal={deal} key={deal.deal_id} />
          ))}
        </section>
      </IonContent>
    </IonPage>
  );
};

export default WalletPage;
