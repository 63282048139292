export interface BusinessLocation {
  lat: number;
  lng: number;
}

export interface BusinessAddress {
  line1: string;
  line2?: string;
  line3?: string;
  level4?: string;
  level3?: string;
  level2: string;
  level1: string;
  postal_code: string;
  country_code: string;
  country_name: string;
}

export interface BusinessContact {
  role?: string;
  name?: string;
  email?: string;
  phone?: string;
}

export interface BusinessImages {
  logo: string;
  map: string;
}

export interface Business {
  type: "business";
  business_id: string;
  slug: string;
  name: string;
  location: BusinessLocation;
  address: BusinessAddress;
  phone?: string;
  images: BusinessImages;
  website?: string;
}

export function getBusinessProfileRoute(bizOrSlug: Business | string): string {
  const slug = typeof bizOrSlug === "string" ? bizOrSlug : bizOrSlug.slug;
  return `/business/${slug}`;
}

export const BUSINESS_FIXTURES: readonly Business[] = [
  // {
  //   type: "business",
  //   business_id: "1",
  //   slug: "pizza-bolis-philly",
  //   name: "Pizza Boli's Philly",
  //   images: {
  //     logo: "/assets/fixtures/pizzabolislarge.jpg",
  //     map: "/assets/fixtures/pizzabolissmall.jpg",
  //   },
  //   location: { lat: 40.0058191, lng: -75.1038627 },
  //   address: {
  //     line1: "3883 Glendale St",
  //     level2: "Philadelphia",
  //     level1: "PA",
  //     postal_code: "19124",
  //     country_code: "US",
  //     country_name: "United States",
  //   },
  //   phone: "+12156135365",
  //   website: "https://www.pizzabolis.com/locations/frankford-juniata/",
  // },
  {
    type: "business",
    business_id: "2",
    slug: "two-cousins-pizza-manheim",
    name: "Two Cousins Pizza Manheim",
    images: {
      logo: "/assets/fixtures/twocousinmainheimlarge.jpg",
      map: "/assets/fixtures/twocousinmainheimsmall.jpg",
    },
    location: { lat: 40.1728422, lng: -76.3791598 },
    address: {
      line1: "171 Doe Run Road",
      level2: "Manheim",
      level1: "PA",
      postal_code: "17545",
      country_code: "US",
      country_name: "United States",
    },
    phone: "+17176650202",
    website: "https://www.twocousinspizzamanheim.com/",
  },
  {
    type: "business",
    business_id: "3",
    slug: "fuego-latino-restaurant-catering",
    name: "Fuego Latino Restaurant & Catering",
    images: {
      logo: "/assets/fixtures/fuegolatinolarge.jpg",
      map: "/assets/fixtures/fuegolatinosmall.jpg",
    },
    location: { lat: 40.1506032, lng: -76.6055307 },
    address: {
      line1: "28 S Market St",
      level2: "Elizabethtown",
      level1: "PA",
      postal_code: "17022",
      country_code: "US",
      country_name: "United States",
    },
    phone: "+17172876276",
    website: "https://www.fuegolatinoetown.com/",
  },
  {
    type: "business",
    business_id: "4",
    slug: "laserdome-lancaster",
    name: "LaserDome",
    location: { lat: 40.115567, lng: -76.4484003 },
    address: {
      line1: "2050 Auction Road",
      level2: "Manheim",
      level1: "PA",
      postal_code: "17545",
      country_code: "US",
      country_name: "United States",
    },
    phone: "+1717.492.0002",
    website: "https://laserdome.com/",
    images: {
      logo: "/assets/fixtures/laserdome-circle.png",
      map: "/assets/fixtures/laserdome-logo.png",
    },
  },
] as const;
