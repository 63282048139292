// Dependency imports
import { pizza, restaurant } from "ionicons/icons";

/** Icon lookup table used by {@link SearchBusinessSuggestion} and {@link SearchTagSuggestion} */
export const SEARCH_SUGGESTION_ICONS = {
  pizza,
  restaurant,
};
export type SearchSuggestionIcon = keyof typeof SEARCH_SUGGESTION_ICONS;

export interface SearchLocationSuggestion {
  type: "location";
  label: string;
  location: { lat: number; lng: number };
}

export interface SearchBusinessSuggestion {
  type: "business";
  label: string;
  id: string;
  icon?: SearchSuggestionIcon;
  street: string;
  city: string;
  state: string;
}

export interface SearchTagSuggestion {
  type: "tag";
  label: string;
  slug: string;
  icon?: SearchSuggestionIcon;
}

export type SearchSuggestion =
  | SearchBusinessSuggestion
  | SearchLocationSuggestion
  | SearchTagSuggestion;

export const FIXTURES: SearchSuggestion[] = [
  {
    type: "business",
    label: "Cabalar Meat Co.",
    id: "cabalar",
    icon: "restaurant",
    street: "325 N Queen St",
    city: "Lancaster",
    state: "PA",
  },
  { type: "tag", label: "Pizza", slug: "pizza", icon: "pizza" },
  { type: "tag", label: "Mini Golf", slug: "mini-golf" },
  { type: "location", label: "Lancaster, PA", location: { lat: 40.0183286, lng: -76.4377772 } },
];
