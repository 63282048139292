// Dependency imports
import type React from "react";
import { connect, ConnectedProps } from "react-redux";
import { withRouter, RouteComponentProps } from "react-router";
import {
  IonHeader,
  IonToolbar,
  IonContent,
  IonPage,
  IonButtons,
  IonBackButton,
  IonButton,
  IonIcon,
  IonText,
  IonList,
  IonItem,
  IonLabel,
} from "@ionic/react";
import { starOutline, star, share, cloudDownload } from "ionicons/icons";
// Project imports
import * as selectors from "../data/selectors";
import { confActions } from "../data/slices/conf";
import type { RootState } from "../data/store";
// Style imports
import "./Session.scss";

const mapStateToProps = (state: RootState, _ownProps: RouteComponentProps) => ({
  session: selectors.getSession(state),
  favoriteSessions: state.conf.favorites,
});

const mapDispatchToProps = {
  addFavorite: confActions.addFavorite,
  removeFavorite: confActions.removeFavorite,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type ConnectorProps = ConnectedProps<typeof connector>;

const SessionPage: React.FC<ConnectorProps> = ({
  session,
  addFavorite,
  removeFavorite,
  favoriteSessions,
}) => {
  if (!session) {
    return <div>Session not found</div>;
  }

  const isFavorite = favoriteSessions.indexOf(session.id) > -1;

  const toggleFavorite = () => {
    isFavorite ? removeFavorite(session.id) : addFavorite(session.id);
  };
  const shareSession = () => {
    console.log(`Clicked share`);
  };
  const sessionClick = (text: string) => {
    console.log(`Clicked ${text}`);
  };

  return (
    <IonPage id="session-detail-page">
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton defaultHref="/schedule"></IonBackButton>
          </IonButtons>
          <IonButtons slot="end">
            <IonButton onClick={() => toggleFavorite()}>
              {isFavorite ? (
                <IonIcon slot="icon-only" icon={star}></IonIcon>
              ) : (
                <IonIcon slot="icon-only" icon={starOutline}></IonIcon>
              )}
            </IonButton>
            <IonButton onClick={() => shareSession}>
              <IonIcon slot="icon-only" icon={share}></IonIcon>
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <div className="ion-padding">
          <h1>{session.name}</h1>
          {session.tracks.map((track) => (
            <span key={track} className={`session-track-${track.toLowerCase()}`}>
              {track}
            </span>
          ))}
          <p>{session.description}</p>
          <IonText color="medium">
            {session.timeStart} &ndash; {session.timeEnd}
            <br />
            {session.location}
          </IonText>
        </div>
        <IonList>
          <IonItem onClick={() => sessionClick("watch")} button>
            <IonLabel color="primary">Watch</IonLabel>
          </IonItem>
          <IonItem onClick={() => sessionClick("add to calendar")} button>
            <IonLabel color="primary">Add to Calendar</IonLabel>
          </IonItem>
          <IonItem onClick={() => sessionClick("mark as unwatched")} button>
            <IonLabel color="primary">Mark as Unwatched</IonLabel>
          </IonItem>
          <IonItem onClick={() => sessionClick("download video")} button>
            <IonLabel color="primary">Download Video</IonLabel>
            <IonIcon slot="end" color="primary" size="small" icon={cloudDownload}></IonIcon>
          </IonItem>
          <IonItem onClick={() => sessionClick("leave feedback")} button>
            <IonLabel color="primary">Leave Feedback</IonLabel>
          </IonItem>
        </IonList>
      </IonContent>
    </IonPage>
  );
};

export default withRouter(connector(SessionPage));
