// Dependency imports
import type React from "react";
import { IonList, IonItem, IonLabel } from "@ionic/react";
import { Browser } from "@capacitor/browser";

interface AboutPopoverProps {
  dismiss: () => void;
}

const AboutPopover: React.FC<AboutPopoverProps> = ({ dismiss }) => {
  const open = (url: string) => {
    Browser.open({ url });
    dismiss();
  };

  return (
    <IonList>
      <IonItem button onClick={() => open("https://ionicframework.com/getting-started")}>
        <IonLabel>Learn Ionic</IonLabel>
      </IonItem>
      <IonItem button onClick={() => open("https://ionicframework.com/docs/react")}>
        <IonLabel>Documentation</IonLabel>
      </IonItem>
      <IonItem button onClick={() => open("https://showcase.ionicframework.com")}>
        <IonLabel>Showcase</IonLabel>
      </IonItem>
      <IonItem button onClick={() => open("https://github.com/ionic-team/ionic")}>
        <IonLabel>GitHub Repo</IonLabel>
      </IonItem>
      <IonItem button onClick={dismiss}>
        <IonLabel>Support</IonLabel>
      </IonItem>
    </IonList>
  );
};

export default AboutPopover;
