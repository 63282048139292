// Dependency imports
import type React from "react";
import { Route, Redirect } from "react-router-dom";
import { IonApp, IonRouterOutlet } from "@ionic/react";
import { IonReactRouter } from "@ionic/react-router";
import classNames from "clsx";
// Project imports
import { useAppSelector } from "./data/hooks";
import AboutPage from "./pages/About";
import Account from "./pages/Account";
import BusinessProfilePage from "./pages/BusinessProfile";
import DealProfilePage from "./pages/DealProfile";
import LaunchPage from "./pages/Launch";
import LoginPage from "./pages/Login";
import SchedulePage from "./pages/Schedule";
import SessionPage from "./pages/Session";
import SignupPage from "./pages/Signup";
import SpeakerPage from "./pages/Speaker";
import SpeakersPage from "./pages/Speakers";
import Support from "./pages/Support";
import Tutorial from "./pages/Tutorial";
import WelcomePage from "./pages/Welcome";
import AuthEmailPage from "./pages/Auth/Email";
import AuthSignupPage from "./pages/Auth/Signup";
import { withGoogleMapsContext } from "./components/GoogleMapsLoader";
import MainTabs from "./components/MainTabs";
import RedirectToLogin from "./components/RedirectToLogin";
import { withNetworkContext } from "./hooks/network";
// Style imports
import styles from "./App.module.scss";

const tabPaths = ["/explore", "/map", "/wallet", "/settings"];

const App: React.FC = () => {
  const isLoading = useAppSelector((state) => state.user.isLoading);
  const isLoggedIn = useAppSelector((state) => state.user.isLoggedIn);
  if (isLoading) return <LaunchPage />;
  const rootRedirect = isLoggedIn ? "/explore" : "/welcome";

  return (
    <IonApp className={classNames(styles["App"])}>
      <IonReactRouter>
        <IonRouterOutlet id="main">
          <Route exact path="/" render={() => <Redirect to={rootRedirect} />} />
          <Route exact path="/welcome" render={() => <WelcomePage />} />
          <Route exact path="/auth/email" render={() => <AuthEmailPage />} />
          <Route exact path="/auth/signup" render={() => <AuthSignupPage />} />
          <Route exact path="/auth/logout" render={() => <RedirectToLogin />} />
          <Route exact path="/deal/:deal_id" render={() => <DealProfilePage />} />
          <Route exact path="/business/:slug" render={() => <BusinessProfilePage />} />
          <Route path={tabPaths} render={() => <MainTabs />} />
          {/* TODO: Delete leftovers from the Ionic Sample Project */}
          <Route exact path="/about" render={() => <AboutPage />} />
          <Route exact path="/schedule" render={() => <SchedulePage />} />
          <Route exact path="/speakers" render={() => <SpeakersPage />} />
          <Route exact path="/speakers/:id" render={() => <SpeakerPage />} />
          <Route exact path="/schedule/:id" render={() => <SessionPage />} />
          <Route exact path="/speakers/sessions/:id" render={() => <SessionPage />} />
          <Route exact path="/account" render={() => <Account />} />
          <Route exact path="/login" render={() => <LoginPage />} />
          <Route exact path="/signup" render={() => <SignupPage />} />
          <Route exact path="/support" render={() => <Support />} />
          <Route exact path="/tutorial" render={() => <Tutorial />} />
        </IonRouterOutlet>
      </IonReactRouter>
    </IonApp>
  );
};

const AppWithContexts = [withGoogleMapsContext, withNetworkContext].reduce(
  (componentToWrap, highOrderComponent) => highOrderComponent(componentToWrap),
  App as React.ComponentType,
);

export default AppWithContexts;
