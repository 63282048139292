import React from "react";
import { Geolocation } from "@capacitor/geolocation";

type LatLng = google.maps.LatLngLiteral;

export function useGoogleMapsGeolocate(): () => Promise<LatLng | undefined> {
  const [resolved, setResolved] = React.useState<LatLng>();
  return async () => {
    if (resolved) return resolved;
    const apiKey = process.env.REACT_APP_GOOGLE_MAPS_GEOLOCATION_API_KEY;
    const url = `https://www.googleapis.com/geolocation/v1/geolocate?key=${apiKey}`;
    try {
      const response = await fetch(url, {
        method: "POST",
        headers: { "content-type": "application/json" },
        body: JSON.stringify({ considerIp: "true" }),
      });
      type ResponseBody = { location?: LatLng };
      const { location }: ResponseBody = await response.json();
      setResolved(location);
      return location;
    } catch (err) {
      console.error("Google Geolocation failure:", err);
      return undefined;
    }
  };
}

export function useDeviceGeolocate(): () => Promise<LatLng | undefined> {
  return async () => {
    try {
      const { coords } = await Geolocation.getCurrentPosition();
      return { lat: coords.latitude, lng: coords.longitude };
    } catch (err) {
      console.error("Device Geolocation failure:", err);
      return undefined;
    }
  };
}

export function useGeolocate(): () => Promise<LatLng | undefined> {
  const deviceGeolocate = useDeviceGeolocate();
  const fallbackGeolocate = useGoogleMapsGeolocate();
  return async () => {
    return (await deviceGeolocate()) ?? (await fallbackGeolocate());
  };
}
