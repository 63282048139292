// Dependency imports
import React from "react";
import { Loader, LoaderOptions } from "@googlemaps/js-api-loader";
// Project imports
import "../theme/google-maps.scss";

export type Google = typeof google;

export enum MapsApiStatus {
  Init = "init",
  Loading = "loading",
  Loaded = "loaded",
  Failed = "failed",
}

type MapsApiState = [Google | undefined, MapsApiStatus, () => void];
type MapsInstanceState = [HTMLElement | undefined, google.maps.Map | undefined];

interface ContextState {
  loader: MapsApiState;
  instance: () => MapsInstanceState;
}

const defaultValue: ContextState = {
  loader: [undefined, MapsApiStatus.Init, () => undefined],
  instance: () => [undefined, undefined],
};

/**
 * This hook manages the state for loading the Google Maps Javascript API.
 *
 * This hook is used by {@link connectGoogleMaps} for {@link GoogleMapsContext} and should
 * not be exposed as public API or used in more than one place in the React Element tree!
 *
 * For public API usage, see {@link useGoogleMapsApi}
 */
function useGoogleMapsApiLoader(options: LoaderOptions): MapsApiState {
  const optionsRef = React.useRef<LoaderOptions>(options);
  if (options !== optionsRef.current) {
    console.warn("useGoogleMapsApiLoader options have changed; this is unsupported!");
  }
  const googleRef = React.useRef<Google>();
  const [status, setStatus] = React.useState(MapsApiStatus.Init);
  let loadWasCalled = false;
  const load = React.useCallback(() => {
    // Don't even attempt to load Google Maps if the API Key is empty
    if (!optionsRef.current.apiKey) return setStatus(MapsApiStatus.Failed);
    // Bail if load was already called, if already loading, or load already happened.
    if (loadWasCalled || status === MapsApiStatus.Loading || googleRef.current) return;
    // Debounce calls by invalidating loadWasCalled whenever the status changes.
    // eslint-disable-next-line react-hooks/exhaustive-deps
    loadWasCalled = true;
    setStatus(MapsApiStatus.Loading);
    new Loader(optionsRef.current)
      .load()
      .then((google) => {
        googleRef.current = google;
        return setStatus(MapsApiStatus.Loaded);
      })
      .catch((err) => {
        console.error("Unable to load Google Maps API:", err);
        return setStatus(MapsApiStatus.Failed);
      });
  }, [status]);
  return [googleRef.current, status, load];
}

/**
 * This hook creates a callback that will create an instance of {@link google.maps.Map}
 * as well as a container <div> that will be attached/detached to the DOM.
 *
 * We need to go out of our way to ensure that it is easy to re-use a single instance
 * of the {@link google.maps.Map} object because there is no way to destroy it!
 * @see https://issuetracker.google.com/issues/35821412?pli=1#comment32
 *
 * This hook is used by {@link connectGoogleMaps} for {@link GoogleMapsContext} and should
 * not be exposed as public API or used in more than one place in the React Element tree!
 *
 * @param google should come from {@link useGoogleMapsApiLoader}.
 * @param initialOptions should be statically-allocated and never change.
 */
function useGoogleMapsInstance(
  google: Google | undefined,
  initialOptions: google.maps.MapOptions = {},
): () => MapsInstanceState {
  const containerRef = React.useRef<HTMLElement>();
  const mapRef = React.useRef<google.maps.Map>();
  const optionsRef = React.useRef(initialOptions);
  if (optionsRef.current !== initialOptions) {
    console.warn("useGoogleMapsInstance options have changed; this is unsupported!");
  }
  return React.useCallback(() => {
    if (!google) {
      return [undefined, undefined];
    }
    if (!containerRef.current || !mapRef.current) {
      const container = document.createElement("div");
      container.id = "google-maps-reusable-instance";
      // Fill the container's parent
      container.style.width = "100%";
      container.style.height = "100%";
      containerRef.current = container;
      mapRef.current = new google.maps.Map(container, optionsRef.current);
    }
    return [containerRef.current, mapRef.current];
  }, [google]);
}

const GoogleMapsContext = React.createContext(defaultValue);
export default GoogleMapsContext;

type Connector = <P>(component: React.ComponentType<P>) => React.ComponentType<P>;

export function connectGoogleMapsContext(
  loaderOptions: LoaderOptions,
  mapOptions?: google.maps.MapOptions,
): Connector {
  return function GoogleMapsContextConnector(component) {
    return function WithGoogleMapsContext(props) {
      const loader = useGoogleMapsApiLoader(loaderOptions);
      const [google] = loader;
      const instance = useGoogleMapsInstance(google, mapOptions);
      const contextState = { loader, instance };
      return (
        <GoogleMapsContext.Provider value={contextState}>
          {React.createElement(component, props)}
        </GoogleMapsContext.Provider>
      );
    };
  };
}

export function useGoogleMapsApi(): MapsApiState {
  const { loader } = React.useContext(GoogleMapsContext);
  const [, status, load] = loader;
  // Automatically load the API as a side-effect the first time this hook is run
  React.useEffect(
    () => {
      if (status === MapsApiStatus.Init) load();
    },
    // Don't set deps: initial load should never re-run, even if the status changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );
  return loader;
}

type MapEffectCallback = (map: google.maps.Map) => ReturnType<React.EffectCallback>;
type UseMapEffect = (effect: MapEffectCallback, deps?: React.DependencyList) => void;

export function useGoogleMap(
  elRef: React.RefObject<HTMLElement | null>,
): [UseMapEffect, google.maps.Map | undefined] {
  const { instance } = React.useContext(GoogleMapsContext);
  const [container, map] = instance();
  React.useEffect(() => {
    if (!container || !map || !elRef.current) return;
    const el = elRef.current;
    el.appendChild(container);
    google.maps.event.trigger(map, "resize");
    return () => {
      el.removeChild(container);
    };
  }, [container, map, elRef]);
  return [useMapEffect.bind(null, map), map];
}

function useMapEffect(
  map: google.maps.Map | undefined,
  effect: MapEffectCallback,
  deps?: React.DependencyList,
) {
  React.useEffect(
    () => {
      if (map) return effect(map);
    },
    // We don't have any way of knowing what the exhaustive deps are for the effect
    // eslint-disable-next-line react-hooks/exhaustive-deps
    deps ? [map, ...deps] : undefined,
  );
}
