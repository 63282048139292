type DateTimeParseFormat = "YYYY-MM-DD";
type DateTimePrintFormat = "MM/DD/YYYY";

function parseDateTime(dateTime: string, format: DateTimeParseFormat): Date {
  if (format === "YYYY-MM-DD") {
    const match = /^(\d{4})-(\d{2})-(\d{2})$/.exec(dateTime);
    if (!match) throw new Error("Invalid dateTime: " + dateTime);
    const year = parseInt(match[1] || "0", 10);
    const monthIndex = parseInt(match[2] || "1", 10) - 1;
    const day = parseInt(match[3] || "1", 10);
    return new Date(year, monthIndex, day);
  }
  throw new Error("Invalid DateTimeParseFormat: " + format);
}

function printDateTime(date: Date, format: DateTimePrintFormat): string {
  if (format === "MM/DD/YYYY") {
    const month = `${date.getMonth() + 1}`.padStart(2, "0");
    const day = `${date.getDate()}`.padStart(2, "0");
    const year = `${date.getFullYear()}`.padStart(4, "0");
    return `${month}/${day}/${year}`;
  }
  throw new Error("Invalid DateTimePrintFormat: " + format);
}

export interface DateTimeProps {
  dateTime: string;
  parseFormat: DateTimeParseFormat;
  printFormat: DateTimePrintFormat;
}

export const DateTime: React.FC<DateTimeProps> = (props) => {
  const parsed = parseDateTime(props.dateTime, props.parseFormat);
  const printFormatted = printDateTime(parsed, props.printFormat);
  return <time dateTime={props.dateTime}>{printFormatted}</time>;
};
