// Dependency imports
import clsx from "clsx";
import { useIonRouter } from "@ionic/react";
// Project imports
import { BemEntity } from "../utils/bem";
import { getDealProfileRoute, getDealTintStyle } from "../models/Deal";
import type { DealWithRelations } from "../models/Deal";
// Style imports
import "./ExploreDealCard.scss";
import { getBusinessProfileRoute } from "../models/Business";

const bem = new BemEntity("ExploreDealCard");

export type ExploreDealCardProps = {
  className?: string;
  deal: DealWithRelations;
};

export const ExploreDealCard: React.FC<ExploreDealCardProps> = ({ className, deal }) => {
  const router = useIonRouter();
  const businessProfileRoute = getBusinessProfileRoute(deal.business);
  const dealProfileRoute = getDealProfileRoute(deal);
  const style = getDealTintStyle(deal);
  return (
    <div className={clsx(className, bem.block())} style={style}>
      <img className={bem.element("header-image")} src={deal.image_header_url} alt="" />
      <a
        className={bem.element("circle")}
        href={businessProfileRoute}
        onClick={(event) => {
          event.preventDefault();
          router.push(businessProfileRoute);
        }}
      >
        {Boolean(deal.business.images.logo) && (
          <img
            className={bem.element("biz-logo")}
            src={deal.business.images.logo}
            alt={"Logo for " + deal.business.name}
          />
        )}
      </a>
      <a
        className={bem.element("biz-name")}
        href={businessProfileRoute}
        onClick={(event) => {
          event.preventDefault();
          router.push(businessProfileRoute);
        }}
      >
        {deal.business.name}
      </a>
      <a
        className={bem.element("message")}
        href={dealProfileRoute}
        onClick={(event) => {
          event.preventDefault();
          router.push(dealProfileRoute);
        }}
      >
        {deal.offer_text}
      </a>
      <a
        className={bem.element("button")}
        href={dealProfileRoute}
        onClick={(event) => {
          event.preventDefault();
          router.push(dealProfileRoute);
        }}
      >
        See Deal
      </a>
    </div>
  );
};
