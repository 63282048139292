// Dependency imports
import { useDispatch } from "react-redux";
import { useIonViewWillEnter, useIonViewWillLeave } from "@ionic/react";
// Project imports
import { confActions } from "../data/slices/conf";

export function useMenuDisabled() {
  const dispatch = useDispatch();
  useIonViewWillEnter(() => {
    dispatch(confActions.setData({ menuEnabled: false }));
  });

  useIonViewWillLeave(() => {
    dispatch(confActions.setData({ menuEnabled: true }));
  });
}
