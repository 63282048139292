// Dependency imports
import { IonSearchbar, IonToolbar } from "@ionic/react";
// Project imports
import { useAppDispatch, useAppSelector } from "../data/hooks";
import { mapActions } from "../data/slices/map";

interface MapSearchToolbarProps {
  className?: string;
}

const MapSearchToolbar: React.FC<MapSearchToolbarProps> = ({ className }) => {
  const searchText = useAppSelector((state) => state.map.searchText);
  const dispatch = useAppDispatch();
  const handleFocus = () => {
    dispatch(mapActions.searchFieldFocused());
  };
  const handleChange = (value?: string) => {
    dispatch(mapActions.searchTextChanged(value || ""));
  };
  const handleSubmit: React.FormEventHandler = (event) => {
    event.preventDefault();
    dispatch(mapActions.searchSubmitted(searchText));
  };
  return (
    <IonToolbar className={className}>
      <form onSubmit={handleSubmit}>
        <IonSearchbar
          value={searchText}
          onIonFocus={handleFocus}
          onIonChange={(event) => handleChange(event.detail.value)}
        />
      </form>
    </IonToolbar>
  );
};

export default MapSearchToolbar;
