// Dependency imports
import type React from "react";
import { connect, ConnectedProps } from "react-redux";
import { getMode } from "@ionic/core";
import {
  IonHeader,
  IonToolbar,
  IonButtons,
  IonButton,
  IonTitle,
  IonContent,
  IonList,
  IonListHeader,
  IonItem,
  IonLabel,
  IonCheckbox,
  IonFooter,
  IonIcon,
} from "@ionic/react";
import {
  logoAngular,
  call,
  document,
  logoIonic,
  hammer,
  restaurant,
  cog,
  colorPalette,
  construct,
  compass,
} from "ionicons/icons";
// Project imports
import { confActions } from "../data/slices/conf";
import type { RootState } from "../data/store";

interface OwnProps {
  onDismissModal: () => void;
}

const mapStateToProps = (state: RootState) => ({
  allTracks: state.conf.allTracks,
  filteredTracks: state.conf.filteredTracks,
});

const mapDispatchToProps = {
  updateFilteredTracks: (filteredTracks: string[]) => confActions.setData({ filteredTracks }),
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type ConnectorProps = ConnectedProps<typeof connector>;

type SessionListFilterProps = OwnProps & ConnectorProps;

const iconMap: { [key: string]: string } = {
  Angular: logoAngular,
  Documentation: document,
  Food: restaurant,
  Ionic: logoIonic,
  Tooling: hammer,
  Design: colorPalette,
  Services: cog,
  Workshop: construct,
  Navigation: compass,
  Communication: call,
};

const SessionListFilter: React.FC<SessionListFilterProps> = ({
  allTracks,
  filteredTracks,
  onDismissModal,
  updateFilteredTracks,
}) => {
  const ios = getMode() === "ios";

  const toggleTrackFilter = (track: string) => {
    if (filteredTracks.indexOf(track) > -1) {
      updateFilteredTracks(filteredTracks.filter((x) => x !== track));
    } else {
      updateFilteredTracks([...filteredTracks, track]);
    }
  };

  const handleDeselectAll = () => {
    updateFilteredTracks([]);
  };

  const handleSelectAll = () => {
    updateFilteredTracks([...allTracks]);
  };

  return (
    <>
      <IonHeader translucent={true}>
        <IonToolbar>
          <IonButtons slot="start">
            {ios && <IonButton onClick={onDismissModal}>Cancel</IonButton>}
            {!ios && <IonButton onClick={handleDeselectAll}>Reset</IonButton>}
          </IonButtons>

          <IonTitle>Filter Sessions</IonTitle>

          <IonButtons slot="end">
            <IonButton onClick={onDismissModal} strong>
              Done
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>

      <IonContent>
        <IonList lines={ios ? "inset" : "full"}>
          <IonListHeader>Tracks</IonListHeader>

          {allTracks.map((track) => (
            <IonItem key={track}>
              {ios && <IonIcon slot="start" icon={iconMap[track]} color="medium" />}
              <IonLabel>{track}</IonLabel>
              <IonCheckbox
                onClick={() => toggleTrackFilter(track)}
                checked={filteredTracks.indexOf(track) !== -1}
                color="primary"
                value={track}
              ></IonCheckbox>
            </IonItem>
          ))}
        </IonList>
      </IonContent>

      {ios && (
        <IonFooter>
          <IonToolbar>
            <IonButtons slot="start">
              <IonButton onClick={handleDeselectAll}>Deselect All</IonButton>
            </IonButtons>
            <IonButtons slot="end">
              <IonButton onClick={handleSelectAll}>Select All</IonButton>
            </IonButtons>
          </IonToolbar>
        </IonFooter>
      )}
    </>
  );
};

export default connector(SessionListFilter);
