// Dependency imports
import { Browser } from "@capacitor/browser";
import { IonContent, IonPage, useIonAlert } from "@ionic/react";
import { useHistory } from "react-router";
// Project imports
import { ReactComponent as ChevronRightIcon } from "../assets/chevron-right.svg";
import { ReactComponent as ShortcutIcon } from "../assets/shortcut.svg";
import { BemEntity } from "../utils/bem";
// Style imports
import "./Settings.scss";

const bem = new BemEntity("SettingsPage");

const urlOpener = (url: string) => () => Browser.open({ url });

const SettingsPage: React.FC = () => {
  const history = useHistory();
  const [presentAlert] = useIonAlert();
  const presentUpdateNameAlert = () => {
    presentAlert({
      header: "Update your name",
      inputs: [
        {
          placeholder: "Full Name",
          type: "text",
          name: "fullName",
          // TODO: Use name from Redux store
          value: "Joe Schmoe",
        },
      ],
      buttons: [{ text: "Done", role: "submit" }],
      onDidDismiss(event) {
        const { role, data } = event.detail;
        if (role === "submit" && data?.values) {
          // TODO: update name in Redux store
          console.log(data.values.fullName);
        }
      },
    });
  };
  const presentUpdateEmailAlert = () => {
    presentAlert({
      header: "Update your email address",
      inputs: [
        {
          placeholder: "Email Address",
          type: "email",
          name: "email",
          // TODO: Use email from Redux store
          value: "user@example.com",
        },
      ],
      buttons: [{ text: "Done", role: "submit" }],
      onDidDismiss(event) {
        const { role, data } = event.detail;
        if (role === "submit" && data?.values) {
          // TODO: update email in Redux store
          console.log(data.values.email);
        }
      },
    });
  };
  const presentChangePasswordAlert = () => {
    presentAlert({
      header: "Change your password",
      inputs: [
        {
          placeholder: "Current Password",
          type: "password",
          name: "currentPassword",
          value: "",
        },
        {
          placeholder: "New Password",
          type: "password",
          name: "newPassword",
          value: "",
        },
        {
          placeholder: "Confirm Password",
          type: "password",
          name: "confirmPassword",
          value: "",
        },
      ],
      buttons: [
        { text: "Cancel", role: "cancel" },
        { text: "Save", role: "submit" },
      ],
      onDidDismiss(event) {
        const { role, data } = event.detail;
        if (role === "submit" && data?.values) {
          // TODO: update password in Redux store
          console.log(data.values);
        }
      },
    });
  };
  return (
    <IonPage id="settings-page" className={bem.block()}>
      <IonContent>
        <p className={bem.element("heading")}>Settings</p>
        <p className={bem.element("subheading")}>Account</p>
        <section className={bem.element("section")}>
          <button className={bem.element("button")} onClick={() => presentUpdateNameAlert()}>
            <span>Update your name</span>
            <ChevronRightIcon className={bem.element("icon", "chevron")} />
          </button>
          {/* TODO: Hide if user logs in via auth provider */}
          <button className={bem.element("button")} onClick={() => presentUpdateEmailAlert()}>
            <span>Update your email address</span>
            <ChevronRightIcon className={bem.element("icon", "chevron")} />
          </button>
          {/* TODO: Hide if user logs in via auth provider */}
          <button className={bem.element("button")} onClick={() => presentChangePasswordAlert()}>
            <span>Change your password</span>
            <ChevronRightIcon className={bem.element("icon", "chevron")} />
          </button>
          <button className={bem.element("button")} onClick={() => history.push("/auth/logout")}>
            <span>Logout</span>
            <ChevronRightIcon className={bem.element("icon", "chevron")} />
          </button>
        </section>
        <p className={bem.element("subheading")}>App</p>
        <section className={bem.element("section")}>
          <button className={bem.element("button")} onClick={urlOpener("https://joincinch.com/")}>
            <span>How Cinch works</span>
            <ShortcutIcon className={bem.element("icon", "shortcut")} />
          </button>
          <button
            className={bem.element("button")}
            onClick={urlOpener("https://joincinch.com/privacy")}
          >
            <span>Privacy policy</span>
            <ShortcutIcon className={bem.element("icon", "shortcut")} />
          </button>
          <button
            className={bem.element("button")}
            onClick={urlOpener("https://joincinch.com/terms")}
          >
            <span>Terms of Service</span>
            <ShortcutIcon className={bem.element("icon", "shortcut")} />
          </button>
          <button
            className={bem.element("button")}
            onClick={urlOpener("https://joincinch.com/faq")}
          >
            <span>FAQs</span>
            <ShortcutIcon className={bem.element("icon", "shortcut")} />
          </button>
          <button
            className={bem.element("button")}
            onClick={urlOpener("https://joincinch.com/feedback")}
          >
            <span>Feature requests/Bug reports</span>
            <ShortcutIcon className={bem.element("icon", "shortcut")} />
          </button>
        </section>
        <p className={bem.element("subheading")}>Follow us</p>
        <section className={bem.element("section")}>
          <button
            className={bem.element("button")}
            onClick={urlOpener("https://facebook.com/joincinch")}
          >
            <span>Follow us on Facebook</span>
            <ShortcutIcon className={bem.element("icon", "shortcut")} />
          </button>
          <button
            className={bem.element("button")}
            onClick={urlOpener("https://twitter.com/joincinch")}
          >
            <span>Follow us on Twitter</span>
            <ShortcutIcon className={bem.element("icon", "shortcut")} />
          </button>
          <button
            className={bem.element("button")}
            onClick={urlOpener("https://instagram.com/joincinch")}
          >
            <span>Follow us on Instagram</span>
            <ShortcutIcon className={bem.element("icon", "shortcut")} />
          </button>
        </section>
        <p className={bem.element("contact-blurb")}>
          Want to share feedback, ask a question, or just get in touch with our team?
        </p>
        <a className={bem.element("contact-link")} href="mailto:contact@joincinch.com">
          Contact Us
        </a>
      </IonContent>
    </IonPage>
  );
};

export default SettingsPage;
