// Dependency imports
import React, { useEffect } from "react";
import { useHistory } from "react-router";
// Project imports
import { useAppDispatch } from "../data/hooks";
import { userActions } from "../data/slices/user";

const RedirectToLogin: React.FC = () => {
  const dispatch = useAppDispatch();
  const history = useHistory();

  useEffect(
    () => {
      dispatch(userActions.setLoggedIn(false));
      dispatch(userActions.setUsername(undefined));
      history.push("/welcome", { direction: "root" });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );
  return null;
};

export default RedirectToLogin;
