// Dependency imports
import clsx from "clsx";
import { connect, ConnectedProps } from "react-redux";
// Project imports
import type { RootState } from "../data/store";
import { DEAL_FIXTURES_WITH_RELATIONS } from "../models/Deal";
import { BemEntity } from "../utils/bem";
import { ExploreDealCard } from "./ExploreDealCard";
// Style imports
import "./ExploreFavoritesShelf.scss";

const bem = new BemEntity("ExploreFavoritesShelf");

const mapStateToProps = (_state: RootState) => ({
  deals: DEAL_FIXTURES_WITH_RELATIONS,
});

const connector = connect(mapStateToProps);
type ConnectorProps = ConnectedProps<typeof connector>;

export interface ExploreFavoritesShelfProps extends ConnectorProps {
  className?: string;
}

export const ExploreFavoritesShelf: React.FC<ExploreFavoritesShelfProps> = ({
  deals,
  className,
}) => {
  return (
    <section className={clsx(className, bem.block())}>
      {deals.map((deal) => (
        <ExploreDealCard key={deal.deal_id} className={bem.element("item")} deal={deal} />
      ))}
    </section>
  );
};

export default connector(ExploreFavoritesShelf);
