// Dependency imports
import React, { useState, useCallback } from "react";
import { connect, ConnectedProps } from "react-redux";
import {
  IonItemDivider,
  IonItemGroup,
  IonLabel,
  IonList,
  IonListHeader,
  IonAlert,
  AlertButton,
} from "@ionic/react";
// Project imports
import type { RootState } from "../data/store";
import { confActions } from "../data/slices/conf";
import type { Schedule, Session } from "../models/Schedule";
import SessionListItem from "./SessionListItem";

interface OwnProps {
  schedule: Schedule;
  listType: "all" | "favorites";
  hide: boolean;
}

const mapStateToProps = (state: RootState) => ({
  favoriteSessions: state.conf.favorites,
});

const mapDispatchToProps = {
  addFavorite: confActions.addFavorite,
  removeFavorite: confActions.removeFavorite,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type ConnectorProps = ConnectedProps<typeof connector>;

interface SessionListProps extends OwnProps, ConnectorProps {}

const SessionList: React.FC<SessionListProps> = ({
  addFavorite,
  removeFavorite,
  favoriteSessions,
  hide,
  schedule,
  listType,
}) => {
  const [showAlert, setShowAlert] = useState(false);
  const [alertHeader, setAlertHeader] = useState("");
  const [alertButtons, setAlertButtons] = useState<(AlertButton | string)[]>([]);

  const handleShowAlert = useCallback((header: string, buttons: AlertButton[]) => {
    setAlertHeader(header);
    setAlertButtons(buttons);
    setShowAlert(true);
  }, []);

  if (schedule.groups.length === 0 && !hide) {
    return (
      <IonList>
        <IonListHeader>No Sessions Found</IonListHeader>
      </IonList>
    );
  }

  return (
    <>
      <IonList style={hide ? { display: "none" } : {}}>
        {schedule.groups.map((group) => (
          <IonItemGroup key={`group-${group.time}`}>
            <IonItemDivider sticky>
              <IonLabel>{group.time}</IonLabel>
            </IonItemDivider>
            {group.sessions.map((session: Session) => (
              <SessionListItem
                onShowAlert={handleShowAlert}
                isFavorite={favoriteSessions.includes(session.id)}
                onAddFavorite={addFavorite}
                onRemoveFavorite={removeFavorite}
                key={`group-${group.time}-${session.id}`}
                session={session}
                listType={listType}
              />
            ))}
          </IonItemGroup>
        ))}
      </IonList>
      <IonAlert
        isOpen={showAlert}
        header={alertHeader}
        buttons={alertButtons}
        onDidDismiss={() => setShowAlert(false)}
      ></IonAlert>
    </>
  );
};

export default connector(SessionList);
