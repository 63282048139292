// Dependency imports
import React from "react";
import { IonSpinner } from "@ionic/react";
import { isPlatform } from "@ionic/react";
// Project imports
import {
  connectGoogleMapsContext,
  useGoogleMapsApi,
  Google,
  MapsApiStatus,
} from "../hooks/google-maps";
import { useNetworkType, useNetworkChangeEffect, NetworkType } from "../hooks/network";

const IS_MOBILE = isPlatform("mobile") || isPlatform("capacitor");

export const withGoogleMapsContext = connectGoogleMapsContext(
  {
    apiKey: process.env.REACT_APP_GOOGLE_MAPS_JS_API_KEY ?? "",
    libraries: ["places"],
  },
  {
    backgroundColor: "transparent",
    fullscreenControl: false,
    mapTypeControl: false,
    panControl: false,
    rotateControl: false,
    scaleControl: !IS_MOBILE,
    streetViewControl: false,
    zoomControl: !IS_MOBILE,
    keyboardShortcuts: !IS_MOBILE,
    // https://developers.google.com/maps/documentation/javascript/overview#zoom-levels
    maxZoom: 18, // Building-level
    minZoom: 5, // Continent-level
  },
);

/**
 * Combines the `useGoogleMaps` and `useNetworkType` hooks to wrap a Map component
 * with the logic to load the Google Maps JS API and handle network connectivity loss.
 */
export function withGoogleMapsLoader<
  PropsWithGoogle extends { google: Google },
  PropsWithoutGoogle = Omit<PropsWithGoogle, "google">,
>(component: React.ComponentType<PropsWithGoogle>): React.ComponentType<PropsWithoutGoogle> {
  return function WithGoogleMapsLoader(props: PropsWithoutGoogle) {
    const [google, mapsApiStatus, reloadMapsApi] = useGoogleMapsApi();
    const networkType = useNetworkType();
    useNetworkChangeEffect((newNetworkType) => {
      // When the network comes back online...
      if (networkType === NetworkType.None && newNetworkType !== NetworkType.None) {
        // Re-attempt to load the Google Maps API if it failed before
        if (mapsApiStatus === MapsApiStatus.Failed) {
          reloadMapsApi();
        }
      }
    });
    if (networkType === NetworkType.None) {
      // TODO This needs to be prettier
      return <div>You must be online to use the map.</div>;
    }
    if (mapsApiStatus === MapsApiStatus.Failed) {
      // TODO This needs to be prettier
      return <div>There was an error loading the Google Maps API</div>;
    }
    if (mapsApiStatus === MapsApiStatus.Loaded && google) {
      // Ignore TypeScript's "could be instantiated with an arbitrary type" whinging
      const propsWithGoogle = { ...props, google } as unknown as PropsWithGoogle;
      return React.createElement(component, propsWithGoogle);
    }
    // TODO This needs to be prettier
    return (
      <div>
        <IonSpinner name="lines" />
        <p>Loading...</p>
      </div>
    );
  };
}
