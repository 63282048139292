// Dependency imports
import { setupIonicReact } from "@ionic/react";
import React from "react";
import ReactDOM from "react-dom";
import * as ReactRedux from "react-redux";
// Project imports
import { confActions } from "./data/slices/conf";
import { userActions } from "./data/slices/user";
import store from "./data/store";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import * as setupServiceWorker from "./setupServiceWorker";
// Style imports
import "./global.scss";

setupIonicReact({
  rippleEffect: false,
});

// Render the React application
const reactRoot = (
  <React.StrictMode>
    <ReactRedux.Provider store={store}>
      <App />
    </ReactRedux.Provider>
  </React.StrictMode>
);
ReactDOM.render(reactRoot, document.getElementById("root"));

// Load initial data after initial render
store.dispatch(confActions.loadData());
store.dispatch(userActions.loadData());

// TODO: Report Web Vitals somewhere
reportWebVitals();
// TODO: Register service worker
setupServiceWorker.unregister();
