import clsx from "clsx";
import { cloneElement } from "react";
import type { HTMLProps, ReactElement } from "react";

import { BemEntity } from "../utils/bem";

import "./Button.scss";

const bem = new BemEntity("Button");

type HTMLButtonProps = HTMLProps<HTMLButtonElement>;

export interface ButtonProps {
  label: string;
  type?: "submit" | "reset" | "button";
  leadingIcon?: ReactElement;
  trailingIcon?: ReactElement;
}

export const Button: React.FC<ButtonProps & HTMLButtonProps> = ({
  className,
  label,
  leadingIcon,
  trailingIcon,
  type = "button",
  ...rest
}) => {
  const blockModifiers = {
    "has-leading-icon": !!leadingIcon,
    "has-trailing-icon": !!trailingIcon,
  };
  return (
    <button className={clsx(className, bem.block(blockModifiers))} type={type} {...rest}>
      {!!leadingIcon &&
        cloneElement(leadingIcon, {
          className: clsx(leadingIcon.props.className, bem.element("leading-icon")),
        })}
      <span className={bem.element("label")}>{label}</span>
      {!!trailingIcon &&
        cloneElement(trailingIcon, {
          className: clsx(trailingIcon.props.className, bem.element("trailing-icon")),
        })}
    </button>
  );
};
