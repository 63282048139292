// Dependency imports
import React from "react";
import classNames from "clsx";
// Project imports
import { useAppSelector } from "../data/hooks";
import { useGoogleMap, Google } from "../hooks/google-maps";
import { useDarkTheme } from "../hooks/dark-theme";
import { darkThemeMapTypeStyles, MapTypeStyles, mergeMapTypeStyles } from "../theme/google-maps";
import {
  useBusinessMarkerOptions,
  generateGeolocationMarkerOptions,
} from "../utils/generate-map-marker";
// Component imports
import { useBusinessDetailModal } from "./BusinessDetailModal";
import { withGoogleMapsLoader } from "./GoogleMapsLoader";
// Style imports
import styles from "./GoogleMap.module.scss";

const ZOOM_DEFAULT = 5;
const ZOOM_GEOLOCATED = 10;
const HOVER_Z_INDEX = 999;

// Hide unnecessary visual elements
const customMapTypeStyles: MapTypeStyles = [
  {
    featureType: "poi",
    stylers: [{ visibility: "off" }],
  },
  {
    featureType: "transit",
    elementType: "labels.icon",
    stylers: [{ visibility: "off" }],
  },
];
const customDarkThemeMapTypeStyles = mergeMapTypeStyles(
  customMapTypeStyles,
  darkThemeMapTypeStyles,
);

interface GoogleMapProps {
  className?: string;
  google: Google;
}

const GoogleMap: React.FC<GoogleMapProps> = ({ className, google }) => {
  const elRef = React.useRef<HTMLDivElement>(null);
  const [isLoaded, setLoaded] = React.useState(false);
  const [currentZoom, setCurrentZoom] = React.useState(ZOOM_DEFAULT);
  const [useMapEffect] = useGoogleMap(elRef);
  const [isDarkTheme] = useDarkTheme();
  const triggerCenter = useAppSelector((state) => state.map.center);
  const triggerZoom = useAppSelector((state) => state.map.zoom);
  const geolocation = useAppSelector((state) => state.map.geolocation);
  const results = useAppSelector((state) => state.map.results);
  const [, resultMarkers] = useBusinessMarkerOptions(results, currentZoom);

  const presentBusinessDetailModal = useBusinessDetailModal();
  const handleResultMarkerClick = React.useCallback(
    (_marker: google.maps.Marker, markersIndex: number): void => {
      const result = results[markersIndex];
      if (!result) throw new Error("Invalid markers index: " + markersIndex);
      presentBusinessDetailModal(result);
    },
    [results, presentBusinessDetailModal],
  );

  useMapEffect((map) => {
    const handleZoomChange = () => {
      const newZoom = map.getZoom();
      if (newZoom) setCurrentZoom(newZoom);
    };
    google.maps.event.addListenerOnce(map, "idle", () => {
      setLoaded(true);
      handleZoomChange();
    });
    google.maps.event.addListener(map, "zoom_changed", handleZoomChange);
    return () => {
      google.maps.event.clearInstanceListeners(map);
    };
  }, []);

  useMapEffect(
    (map) => {
      map.setOptions({
        styles: isDarkTheme ? customDarkThemeMapTypeStyles : customMapTypeStyles,
      });
    },
    [isDarkTheme],
  );

  useMapEffect(
    (map) => {
      if (triggerCenter) map.setCenter(triggerCenter);
    },
    [triggerCenter],
  );

  useMapEffect(
    (map) => {
      if (triggerZoom) map.setZoom(triggerZoom);
    },
    [triggerZoom],
  );

  useMapEffect(
    (map) => {
      if (!geolocation) return;
      map.setCenter(geolocation);
      map.setZoom(ZOOM_GEOLOCATED);
      const markerOptions = generateGeolocationMarkerOptions(geolocation);
      const marker = new google.maps.Marker({ ...markerOptions, map });
      return () => {
        google.maps.event.clearInstanceListeners(marker);
        marker.setMap(null);
      };
    },
    [geolocation],
  );

  useMapEffect(
    (map) => {
      if (!resultMarkers.length) return;
      const markers = resultMarkers.map((markerOptions, index) => {
        const zIndex = markerOptions.zIndex ?? resultMarkers.length - index;
        const marker = new google.maps.Marker({ ...markerOptions, map, zIndex });
        google.maps.event.addListener(marker, "click", () => {
          handleResultMarkerClick(marker, index);
        });
        let isHovering = false;
        google.maps.event.addListener(marker, "mouseover", () => {
          if (!isHovering) {
            isHovering = true;
            const originalZIndex = marker.getZIndex();
            marker.setZIndex(HOVER_Z_INDEX);
            google.maps.event.addListenerOnce(marker, "mouseout", () => {
              marker.setZIndex(originalZIndex);
              isHovering = false;
            });
          }
        });
        return marker;
      });
      return () => {
        markers.forEach((marker) => {
          google.maps.event.clearInstanceListeners(marker);
          marker.setMap(null);
        });
      };
    },
    [resultMarkers, handleResultMarkerClick],
  );

  useMapEffect(
    (map) => {
      if (!results.length) return;
      const bounds = new google.maps.LatLngBounds();
      results.forEach((result) => {
        bounds.extend(result.location);
      });
      map.fitBounds(bounds);
    },
    [results],
  );

  return (
    <div
      ref={elRef}
      className={classNames(className, styles["GoogleMap"], {
        [`${styles["GoogleMap--loaded"]}`]: isLoaded,
      })}
    />
  );
};

export default withGoogleMapsLoader(GoogleMap);
