// Dependency imports
import { Geolocation } from "@capacitor/geolocation";
import { IonContent, IonHeader, IonPage, useIonAlert } from "@ionic/react";
import { useEffect } from "react";
// Project imports
import GoogleMap from "../components/GoogleMap";
import MapSearchToolbar from "../components/MapSearchToolbar";
import MapSuggestionsMenu from "../components/MapSuggestionsMenu";
import MapButtonsToolbar from "../components/MapButtonsToolbar";
import { useAppDispatch } from "../data/hooks";
import { useGeolocate } from "../hooks/geolocate";
import { mapActions } from "../data/slices/map";
// Style imports
import "./Map.scss";
import { BemEntity } from "../utils/bem";

const bem = new BemEntity("MapPage");

function useMapGeolocateActionCallback(): () => Promise<void> {
  const dispatch = useAppDispatch();
  const geolocate = useGeolocate();
  const [presentAlert] = useIonAlert();
  return async () => {
    try {
      const geolocationPromise = geolocate();
      await dispatch(mapActions.geolocate(geolocationPromise));
    } catch (err: unknown) {
      console.error("Failed to geolocate:", err);
      presentAlert("Unable to determine your location");
    }
  };
}

function useMapGeolocatePermissionCallback(): () => Promise<void> {
  const [presentAlert] = useIonAlert();
  const geolocateAction = useMapGeolocateActionCallback();
  return async () => {
    const status = await Geolocation.checkPermissions();
    if (status.location === "denied") return;
    if (status.location === "granted") return geolocateAction();
    presentAlert({
      header: "Find local businesses",
      message: "Cinch can use your location to show you nearby deals.",
      buttons: [
        { text: "No Thanks", role: "deny" },
        { text: "Sure!", role: "grant" },
      ],
      onDidDismiss(event) {
        if (event.detail.role === "grant") {
          geolocateAction();
        }
      },
    });
  };
}

function useMapInitialGeolocateEffect() {
  const geolocatePermission = useMapGeolocatePermissionCallback();
  useEffect(
    () => {
      geolocatePermission();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );
}

const MapPage: React.FC = () => {
  useMapInitialGeolocateEffect();
  return (
    <IonPage id="map-page" className={bem.block()}>
      <IonContent className={bem.element("content")}>
        <IonHeader className={bem.element("overlay")}>
          <MapSearchToolbar className={bem.element("search-toolbar")} />
          <MapSuggestionsMenu className={bem.element("suggestions-menu")} />
          <MapButtonsToolbar className={bem.element("buttons-toolbar")} />
        </IonHeader>
        <GoogleMap className={bem.element("map")} />
      </IonContent>
    </IonPage>
  );
};

export default MapPage;
