import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { getConfData } from "../dataApi";
import type { Location } from "../../models/Location";
import type { Speaker } from "../../models/Speaker";
import type { Schedule, Session } from "../../models/Schedule";

export interface ConfState {
  schedule: Schedule;
  sessions: Session[];
  speakers: Speaker[];
  favorites: number[];
  locations: Location[];
  filteredTracks: string[];
  searchText?: string;
  mapCenterId?: number;
  isLoading?: boolean;
  allTracks: string[];
  menuEnabled: boolean;
}

export const initialState: ConfState = {
  schedule: { date: "", groups: [] },
  sessions: [],
  speakers: [],
  favorites: [],
  locations: [],
  allTracks: [],
  filteredTracks: [],
  mapCenterId: 0,
  isLoading: false,
  menuEnabled: true,
};

const sessionsSlice = createSlice({
  name: "sessions",
  initialState,
  reducers: {
    setData: (state, action: PayloadAction<Partial<ConfState>>) => {
      return { ...state, ...action.payload };
    },
    addFavorite: (state, action: PayloadAction<number>) => {
      state.favorites.push(action.payload);
    },
    removeFavorite: (state, action: PayloadAction<number>) => {
      state.favorites = state.favorites.filter((sessionId) => {
        return sessionId !== action.payload;
      });
    },
  },
});

const { actions, reducer } = sessionsSlice;
const thunks = {
  loadData: createAsyncThunk("conf/loadData", async (_arg, { dispatch }) => {
    dispatch(actions.setData({ isLoading: true }));
    dispatch(actions.setData(await getConfData()));
    dispatch(actions.setData({ isLoading: false }));
  }),
};

const allActions = { ...actions, ...thunks };
export { reducer as confReducer, allActions as confActions };
