import React, { useState } from "react";
import { connect, ConnectedProps } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router";
import { IonContent, IonPage } from "@ionic/react";
// Project imports
import { ReactComponent as CloseIcon } from "../../assets/close.svg";
import { ReactComponent as ChevronRightIcon } from "../../assets/chevron-right.svg";
import { ReactComponent as CinchLogotype } from "../../assets/cinch-logotype.svg";
import { Button } from "../../components/Button";
import { CircleButton } from "../../components/CircleButton";
import { userActions } from "../../data/slices/user";
import { useMenuDisabled } from "../../hooks/menu-disabled";
import { BemEntity } from "../../utils/bem";
// Style imports
import "./Auth.scss";

const bem = new BemEntity("AuthPage");

const mapDispatchToProps = {
  setIsLoggedIn: userActions.setLoggedIn,
  setUsername: userActions.setUsername,
};

const connector = connect(null, mapDispatchToProps);
type ConnectorProps = ConnectedProps<typeof connector>;

interface AuthSignupPageProps extends RouteComponentProps, ConnectorProps {}

const AuthSignupPage: React.FC<AuthSignupPageProps> = ({
  history,
  setIsLoggedIn,
  setUsername: setUsernameAction,
}) => {
  useMenuDisabled();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [fullName, setFullName] = useState("");
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [formDisabled, setFormDisabled] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const [fullNameError, setFullNameError] = useState(false);

  const resetFormState = () => {
    setFormDisabled(false);
    setFormSubmitted(false);
    setEmail("");
    setPassword("");
    setFullName("");
    setEmailError(false);
    setPasswordError(false);
    setFullNameError(false);
  };

  const redirectToWelcomePage = () => {
    history.push("/welcome", { direction: "none" });
    resetFormState();
  };

  const submitForm = async (e: React.FormEvent) => {
    e.preventDefault();
    setFormSubmitted(true);
    if (!email) {
      setEmailError(true);
    }
    if (!password) {
      setPasswordError(true);
    }
    if (!fullName) {
      setFullNameError(true);
    }

    if (email && password && fullName) {
      // TODO Use a real sign up action here
      await setIsLoggedIn(true);
      await setUsernameAction(email);
      history.push("/explore", { direction: "none" });
    }
  };

  return (
    <IonPage id="auth-signup-page" className={bem.block()}>
      <IonContent>
        <header className={bem.element("header")}>
          <CinchLogotype className={bem.element("logo")} />
          <CircleButton
            className={bem.element("close")}
            icon={<CloseIcon />}
            title="Back"
            onClick={redirectToWelcomePage}
          />
        </header>
        <p className={bem.element("heading")}>Tell us about yourself</p>
        <form className={bem.element("form")} noValidate onSubmit={submitForm}>
          <fieldset disabled={formDisabled}>
            <div className={bem.element("field")}>
              <label htmlFor="auth-email-input">Email Address</label>
              <input
                id="auth-email-input"
                name="email"
                type="text"
                value={email}
                spellCheck={false}
                autoCapitalize="off"
                required
                onChange={(e) => {
                  setEmail(e.currentTarget.value || "");
                  setEmailError(false);
                }}
              />
              {formSubmitted && emailError && (
                <span className={bem.element("error-msg")}>Email Address is required</span>
              )}
            </div>
            <div className={bem.element("field")}>
              <label htmlFor="auth-password-input">Password</label>
              <input
                id="auth-password-input"
                name="password"
                type="password"
                value={password}
                required
                onChange={(e) => {
                  setPassword(e.currentTarget.value || "");
                  setPasswordError(false);
                }}
              />
              {formSubmitted && passwordError && (
                <span className={bem.element("error-msg")}>Password is required</span>
              )}
            </div>
            <div className={bem.element("field")}>
              <label htmlFor="auth-fullname-input">Full Name</label>
              <input
                id="auth-fullname-input"
                name="fullname"
                type="text"
                value={fullName}
                spellCheck={false}
                autoCapitalize="off"
                required
                onChange={(e) => {
                  setFullName(e.currentTarget.value || "");
                  setFullNameError(false);
                }}
              />
              {formSubmitted && fullNameError && (
                <span className={bem.element("error-msg")}>Full Name is required</span>
              )}
            </div>

            <hr className={bem.element("spacer")} />

            <Button type="submit" label="Sign up" trailingIcon={<ChevronRightIcon />} />
            <Button
              className={bem.element("button", "link")}
              label="Already on Cinch? Log in"
              onClick={redirectToWelcomePage}
            />
          </fieldset>
        </form>
      </IonContent>
    </IonPage>
  );
};

export default connector(withRouter(AuthSignupPage));
