/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

// Dependency imports
import { createSelector } from "@reduxjs/toolkit";
// Project imports
import type { Schedule, Session, ScheduleGroup } from "../models/Schedule";
import type { RootState } from "./store";

const getSchedule = (state: RootState) => state.conf.schedule;
export const getSpeakers = (state: RootState) => state.conf.speakers;
const getSessions = (state: RootState) => state.conf.sessions;
const getFilteredTracks = (state: RootState) => state.conf.filteredTracks;
const getFavoriteIds = (state: RootState) => state.conf.favorites;
const getSearchText = (state: RootState) => state.conf.searchText;

export const getFilteredSchedule = createSelector(
  getSchedule,
  getFilteredTracks,
  (schedule, filteredTracks) => {
    const groups: ScheduleGroup[] = [];
    schedule.groups.forEach((group) => {
      const sessions: Session[] = [];
      group.sessions.forEach((session) => {
        session.tracks.forEach((track) => {
          if (filteredTracks.indexOf(track) > -1) {
            sessions.push(session);
          }
        });
      });
      if (sessions.length) {
        const groupToAdd: ScheduleGroup = {
          time: group.time,
          sessions,
        };
        groups.push(groupToAdd);
      }
    });

    return {
      date: schedule.date,
      groups,
    } as Schedule;
  },
);

export const getSearchedSchedule = createSelector(
  getFilteredSchedule,
  getSearchText,
  (schedule, searchText) => {
    if (!searchText) {
      return schedule;
    }
    const groups: ScheduleGroup[] = [];
    schedule.groups.forEach((group) => {
      const sessions = group.sessions.filter(
        (s) => s.name.toLowerCase().indexOf(searchText.toLowerCase()) > -1,
      );
      if (sessions.length) {
        const groupToAdd: ScheduleGroup = {
          time: group.time,
          sessions,
        };
        groups.push(groupToAdd);
      }
    });
    return {
      date: schedule.date,
      groups,
    } as Schedule;
  },
);

export const getScheduleList = createSelector(getSearchedSchedule, (schedule) => schedule);

export const getGroupedFavorites = createSelector(
  getScheduleList,
  getFavoriteIds,
  (schedule, favoriteIds) => {
    const groups: ScheduleGroup[] = [];
    schedule.groups.forEach((group) => {
      const sessions = group.sessions.filter((s) => favoriteIds.indexOf(s.id) > -1);
      if (sessions.length) {
        const groupToAdd: ScheduleGroup = {
          time: group.time,
          sessions,
        };
        groups.push(groupToAdd);
      }
    });
    return {
      date: schedule.date,
      groups,
    } as Schedule;
  },
);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const getIdParam = (_state: RootState, routerProps: any) => {
  return routerProps?.match?.params["id"];
};

export const getSession = createSelector(getSessions, getIdParam, (sessions, id) => {
  return sessions.find((s) => s.id === id);
});

export const getSpeaker = createSelector(getSpeakers, getIdParam, (speakers, id) =>
  speakers.find((x) => x.id === id),
);

export const getSpeakerSessions = createSelector(getSessions, (sessions) => {
  const speakerSessions: { [key: string]: Session[] } = {};

  sessions.forEach((session) => {
    session.speakerNames &&
      session.speakerNames.forEach((name) => {
        const sessions = speakerSessions[name] ?? [];
        sessions.push(session);
        speakerSessions[name] = sessions;
      });
  });
  return speakerSessions;
});

/* eslint-enable @typescript-eslint/explicit-module-boundary-types */
