// Dependency imports
import React from "react";
// Project imports
import { useMatchMedia } from "./media";

function useToggleState(defaultValue = false): [boolean, (value?: boolean) => void] {
  const [value, setValue] = React.useState(defaultValue);
  const toggleValue = React.useCallback((value?: boolean) => {
    if (typeof value === "boolean") setValue(value);
    else setValue((currentValue) => !currentValue);
  }, []);
  return [value, toggleValue];
}

type ToggleState = ReturnType<typeof useToggleState>;
const defaultValue: ToggleState = [false, () => undefined];

function useDarkThemeContextState(): ToggleState {
  const defaultValue = useMatchMedia("(prefers-color-scheme: dark)", onMediaChange);
  const [value, toggleValue] = useToggleState(defaultValue);
  return [value, toggleValue];

  function onMediaChange(event: MediaQueryListEvent) {
    toggleValue(event.matches);
  }
}

const DarkThemeContext = React.createContext(defaultValue);
export default DarkThemeContext;

export function useDarkTheme(): ToggleState {
  return React.useContext(DarkThemeContext);
}

export function withDarkThemeContext<P>(component: React.ComponentType<P>): React.ComponentType<P> {
  return function WithDarkThemeContext(props: P) {
    const contextState = useDarkThemeContextState();
    return (
      <DarkThemeContext.Provider value={contextState}>
        {React.createElement(component, props)}
      </DarkThemeContext.Provider>
    );
  };
}
