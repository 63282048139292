// Dependency imports
import { configureStore } from "@reduxjs/toolkit";
// Project imports
import { confReducer } from "./slices/conf";
import { mapReducer } from "./slices/map";
import { userReducer } from "./slices/user";

const store = configureStore({
  reducer: {
    conf: confReducer,
    map: mapReducer,
    user: userReducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
