/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

import { Preferences } from "@capacitor/preferences";
import type { Schedule, Session } from "../models/Schedule";
import type { Speaker } from "../models/Speaker";
import type { Location } from "../models/Location";

const dataUrl = "/assets/data/data.json";
const locationsUrl = "/assets/data/locations.json";

const HAS_LOGGED_IN = "hasLoggedIn";
const HAS_SEEN_TUTORIAL = "hasSeenTutorial";
const USERNAME = "username";
const EMAIL = "email";

export const getConfData = async () => {
  const response = await Promise.all([fetch(dataUrl), fetch(locationsUrl)]);
  const responseData = await response[0].json();
  const schedule = responseData.schedule[0] as Schedule;
  const sessions = parseSessions(schedule);
  const speakers = responseData.speakers as Speaker[];
  const locations = (await response[1].json()) as Location[];
  const allTracks = sessions
    .reduce((all, session) => all.concat(session.tracks), [] as string[])
    .filter((trackName, index, array) => array.indexOf(trackName) === index)
    .sort();

  const data = {
    schedule,
    sessions,
    locations,
    speakers,
    allTracks,
    filteredTracks: [...allTracks],
  };
  return data;
};

export const getUserData = async () => {
  const response = await Promise.all([
    Preferences.get({ key: HAS_LOGGED_IN }),
    Preferences.get({ key: HAS_SEEN_TUTORIAL }),
    Preferences.get({ key: USERNAME }),
    // DEMO: Hang on the splash screen for dramatic effect
    new Promise((resolve) => setTimeout(resolve, 1000)),
  ]);
  const isLoggedIn = response[0].value === "true";
  const hasSeenTutorial = response[1].value === "true";
  const username = response[2].value || undefined;
  const data = {
    isLoggedIn,
    hasSeenTutorial,
    username,
  };
  return data;
};

export const setIsLoggedInData = async (isLoggedIn: boolean) => {
  await Preferences.set({ key: HAS_LOGGED_IN, value: JSON.stringify(isLoggedIn) });
};

export const setHasSeenTutorialData = async (hasSeenTutorial: boolean) => {
  await Preferences.set({
    key: HAS_SEEN_TUTORIAL,
    value: JSON.stringify(hasSeenTutorial),
  });
};

export const setEmailData = async (email?: string) => {
  if (!email) {
    await Preferences.remove({ key: EMAIL });
  } else {
    await Preferences.set({ key: EMAIL, value: email });
  }
};

export const setUsernameData = async (username?: string) => {
  if (!username) {
    await Preferences.remove({ key: USERNAME });
  } else {
    await Preferences.set({ key: USERNAME, value: username });
  }
};

function parseSessions(schedule: Schedule) {
  const sessions: Session[] = [];
  schedule.groups.forEach((g) => {
    g.sessions.forEach((s) => sessions.push(s));
  });
  return sessions;
}

/* eslint-enable @typescript-eslint/explicit-module-boundary-types */
