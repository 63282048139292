import { BemEntity } from "../utils/bem";
import { ReactComponent as CinchLogotype } from "../assets/cinch-logotype.svg";

import "./Launch.scss";

const bem = new BemEntity("LaunchPage");

const LaunchPage: React.FC = () => {
  return (
    <div className={bem.block()}>
      <CinchLogotype className={bem.element("logotype")} />
    </div>
  );
};

export default LaunchPage;
