// Dependency imports
import { connect, ConnectedProps } from "react-redux";
import {
  IonHeader,
  IonToolbar,
  IonTitle,
  IonContent,
  IonPage,
  IonButtons,
  IonMenuButton,
  IonGrid,
  IonRow,
  IonCol,
} from "@ionic/react";
// Project imports
import SpeakerItem from "../components/SpeakerItem";
import * as selectors from "../data/selectors";
import type { RootState } from "../data/store";
// Style imports
import "./Speakers.scss";

const mapStateToProps = (state: RootState) => ({
  speakers: selectors.getSpeakers(state),
  speakerSessions: selectors.getSpeakerSessions(state),
});

const connector = connect(mapStateToProps);
type ConnectorProps = ConnectedProps<typeof connector>;

const SpeakersPage: React.FC<ConnectorProps> = ({ speakers, speakerSessions }) => {
  return (
    <IonPage id="speaker-list">
      <IonHeader translucent={true}>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle>Speakers</IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent fullscreen={true}>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">Speakers</IonTitle>
          </IonToolbar>
        </IonHeader>

        <IonGrid fixed>
          <IonRow>
            {speakers.map((speaker) => {
              const sessions = speakerSessions[speaker.name];
              if (sessions) {
                return (
                  <IonCol size="12" size-md="6" key={speaker.id}>
                    <SpeakerItem key={speaker.id} speaker={speaker} sessions={sessions} />
                  </IonCol>
                );
              }
              return null;
            })}
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

export default connector(SpeakersPage);
