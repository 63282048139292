// Dependency imports
import { Browser } from "@capacitor/browser";
import { IonContent, IonPage, useIonRouter } from "@ionic/react";
import { connect, ConnectedProps } from "react-redux";
import { withRouter, RouteComponentProps } from "react-router";
// Project imports
import { ReactComponent as CloseIcon } from "../assets/close.svg";
import { CircleButton } from "../components/CircleButton";
import type { RootState } from "../data/store";
import { BUSINESS_FIXTURES } from "../models/Business";
import type { Business } from "../models/Business";
// import { DEAL_FIXTURES_WITH_RELATIONS } from "../models/Deal";
// import type { DealWithRelations } from "../models/Deal";
import { BemEntity } from "../utils/bem";
// Style imports
import "./BusinessProfile.scss";

const bem = new BemEntity("BusinessProfilePage");

type RouteParams = { slug: string };

interface StateProps {
  business: Business;
}

const mapStateToProps = (
  _state: RootState,
  routerProps: RouteComponentProps<RouteParams>,
): StateProps => {
  const business = BUSINESS_FIXTURES.find((business) => {
    return business.slug === routerProps.match.params.slug;
  });
  if (!business) throw new Error("Invalid business slug");
  return { business };
};

const connector = connect(mapStateToProps);
type ConnectorProps = ConnectedProps<typeof connector>;

interface BusinessProfilePageProps extends ConnectorProps, RouteComponentProps<RouteParams> {}

const BusinessProfilePage: React.FC<BusinessProfilePageProps> = ({ business }) => {
  const router = useIonRouter();
  const businessWebsiteUrl = business.website;
  return (
    <IonPage id="business-profile-page" className={bem.block()}>
      <IonContent>
        <CircleButton
          className={bem.element("close-button")}
          icon={<CloseIcon />}
          onClick={(event) => {
            event.preventDefault();
            if (router.canGoBack()) {
              router.goBack();
            } else {
              router.push("/explore", "root");
            }
          }}
        />
        <div className={bem.element("biz-info")}>
          <p className={bem.element("biz-name")}>{business.name}</p>
          <address className={bem.element("biz-address")}>
            {[
              business.address.line1,
              business.address.line2,
              business.address.line3,
              business.address.level4,
              business.address.level3,
              business.address.level2,
              business.address.level1,
            ]
              .filter(Boolean)
              .join(", ")}
          </address>
          {typeof businessWebsiteUrl === "string" && (
            <a
              className={bem.element("biz-website")}
              href={businessWebsiteUrl}
              onClick={(event) => {
                event.preventDefault();
                Browser.open({ url: businessWebsiteUrl });
              }}
            >
              {businessWebsiteUrl}
            </a>
          )}
        </div>
      </IonContent>
    </IonPage>
  );
};

export default withRouter(connector(BusinessProfilePage));
