// Dependency imports
import React from "react";
import { IonButton, IonContent, IonLabel, useIonModal } from "@ionic/react";
// Project imports
import type { Business } from "../models/Business";

type PresentCallback = (data: Business) => void;

export function useBusinessDetailModal(): PresentCallback {
  const [data, setData] = React.useState<Business>();
  const [present, dismiss] = useIonModal(BusinessDetailModalContent, {
    data,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    onDismiss: React.useCallback(() => dismiss(), []),
  });
  return React.useCallback(
    (data: Business) => {
      setData(data);
      present({
        onDidDismiss: () => {
          setData(undefined);
        },
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );
}

interface BusinessDetailModalContentProps {
  data: Business | undefined;
  onDismiss: () => void;
}

export const BusinessDetailModalContent: React.FC<BusinessDetailModalContentProps> = ({
  data,
  onDismiss,
}) => {
  if (!data) return null;
  return (
    <IonContent>
      <IonButton onClick={() => onDismiss()}>Close</IonButton>
      <IonLabel>{data.name}</IonLabel>
    </IonContent>
  );
};
