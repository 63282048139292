// Dependency imports
import React, { useState, useRef } from "react";
import { connect, ConnectedProps } from "react-redux";
import {
  IonToolbar,
  IonContent,
  IonPage,
  IonButtons,
  IonTitle,
  IonMenuButton,
  IonSegment,
  IonSegmentButton,
  IonButton,
  IonIcon,
  IonSearchbar,
  IonRefresher,
  IonRefresherContent,
  IonToast,
  IonModal,
  IonHeader,
  getConfig,
} from "@ionic/react";
import { options, search } from "ionicons/icons";
// Project imports
import SessionList from "../components/SessionList";
import SessionListFilter from "../components/SessionListFilter";
import ShareSocialFab from "../components/ShareSocialFab";
import * as selectors from "../data/selectors";
import { confActions } from "../data/slices/conf";
import type { RootState } from "../data/store";
import type { Schedule } from "../models/Schedule";
// Style imports
import sessionListFilterStyles from "../components/SessionListFilter.module.scss";
import "./Schedule.scss";

type Segment = "all" | "favorites";

interface StateProps {
  schedule: Schedule;
  favoritesSchedule: Schedule;
  mode: "ios" | "md";
}

const mapStateToProps = (state: RootState): StateProps => ({
  mode: getConfig()?.get("mode") || "md",
  schedule: selectors.getSearchedSchedule(state),
  favoritesSchedule: selectors.getGroupedFavorites(state),
});

const mapDispatchToProps = {
  setSearchText: (searchText: string) => confActions.setData({ searchText }),
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type ConnectorProps = ConnectedProps<typeof connector>;

const SchedulePage: React.FC<ConnectorProps> = ({
  mode,
  favoritesSchedule,
  schedule,
  setSearchText,
}) => {
  const [segment, setSegment] = useState<Segment>("all");
  const [showSearchbar, setShowSearchbar] = useState<boolean>(false);
  const [showFilterModal, setShowFilterModal] = useState(false);
  const ionRefresherRef = useRef<HTMLIonRefresherElement>(null);
  const [showCompleteToast, setShowCompleteToast] = useState(false);

  const pageRef = useRef<HTMLElement>(null);

  const ios = mode === "ios";

  const doRefresh = () => {
    setTimeout(() => {
      ionRefresherRef.current?.complete();
      setShowCompleteToast(true);
    }, 2500);
  };

  return (
    <IonPage ref={pageRef} id="schedule-page">
      <IonHeader translucent={true}>
        <IonToolbar>
          {!showSearchbar && (
            <IonButtons slot="start">
              <IonMenuButton />
            </IonButtons>
          )}
          {ios && (
            <IonSegment value={segment} onIonChange={(e) => setSegment(e.detail.value as Segment)}>
              <IonSegmentButton value="all">All</IonSegmentButton>
              <IonSegmentButton value="favorites">Favorites</IonSegmentButton>
            </IonSegment>
          )}
          {!ios && !showSearchbar && <IonTitle>Schedule</IonTitle>}
          {showSearchbar && (
            <IonSearchbar
              showCancelButton="always"
              placeholder="Search"
              onIonChange={(e: CustomEvent) => setSearchText(e.detail.value)}
              onIonCancel={() => setShowSearchbar(false)}
            ></IonSearchbar>
          )}

          <IonButtons slot="end">
            {!ios && !showSearchbar && (
              <IonButton onClick={() => setShowSearchbar(true)}>
                <IonIcon slot="icon-only" icon={search}></IonIcon>
              </IonButton>
            )}
            {!showSearchbar && (
              <IonButton onClick={() => setShowFilterModal(true)}>
                {mode === "ios" ? "Filter" : <IonIcon icon={options} slot="icon-only" />}
              </IonButton>
            )}
          </IonButtons>
        </IonToolbar>

        {!ios && (
          <IonToolbar>
            <IonSegment value={segment} onIonChange={(e) => setSegment(e.detail.value as Segment)}>
              <IonSegmentButton value="all">All</IonSegmentButton>
              <IonSegmentButton value="favorites">Favorites</IonSegmentButton>
            </IonSegment>
          </IonToolbar>
        )}
      </IonHeader>

      <IonContent fullscreen={true}>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">Schedule</IonTitle>
          </IonToolbar>
          <IonToolbar>
            <IonSearchbar
              placeholder="Search"
              onIonChange={(e: CustomEvent) => setSearchText(e.detail.value)}
            ></IonSearchbar>
          </IonToolbar>
        </IonHeader>

        <IonRefresher slot="fixed" ref={ionRefresherRef} onIonRefresh={doRefresh}>
          <IonRefresherContent />
        </IonRefresher>

        <IonToast
          isOpen={showCompleteToast}
          message="Refresh complete"
          duration={2000}
          onDidDismiss={() => setShowCompleteToast(false)}
        />

        <SessionList schedule={schedule} listType={segment} hide={segment === "favorites"} />
        <SessionList
          // schedule={schedule}
          schedule={favoritesSchedule}
          listType={segment}
          hide={segment === "all"}
        />
      </IonContent>

      <IonModal
        isOpen={showFilterModal}
        onDidDismiss={() => setShowFilterModal(false)}
        canDismiss={true}
        presentingElement={pageRef.current as HTMLElement}
        className={sessionListFilterStyles.modal}
      >
        <SessionListFilter onDismissModal={() => setShowFilterModal(false)} />
      </IonModal>

      <ShareSocialFab />
    </IonPage>
  );
};

export default connector(SchedulePage);
